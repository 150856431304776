<template lang="html">
  <div class="p-3 p-lg-4" v-if="$store.state.device.data">

    <b-breadcrumb
      class="d-lg-none"
      :items="[
        /* {text: 'Устройства', to: '/devices'}, */
        {text: `${$store.state.device.man} № ${$store.state.device.serial_number}`}
      ]">
    </b-breadcrumb>

    <div class="mb-3 fs-14 d-lg-none">
      <strong>{{
        ['heat_flow_pipe', 'heat_return_pipe'].includes($store.state.device.device_type) ?
          (lang === 'ru' ? 'Теплосчетчик' : 'Теплолічильник')
          :
          (lang === 'ru' ? 'Неизвестное устройство' : 'Невідомий пристрій')
      }}</strong>
      по адресу
      {{ lang === 'ru' ? $store.state.device.object.group_name : $store.state.device.object.group_name_ua }}
      {{ lang === 'ru' ? $store.state.device.object.name : $store.state.device.object.name_ua }}
      № {{ $store.state.device.apartment_number }}
      ({{ {'residential': (lang === 'ru' ? 'Жилое' : 'Житлове'), 'commercial': (lang === 'ru' ? 'Коммерческое' : 'Коммерційне')}[$store.state.device.apartment_type] }})
    </div>

    <div class="mb-3 fs-14 fw-700 text-nowrap d-none d-lg-flex">
      <table>
        <tbody>
          <tr>
            <td class="pr-3">{{ lang === 'ru' ? 'Устройство' : 'Пристрій' }}:</td>
            <td class="border-right pr-3">
              {{ ['heat_flow_pipe', 'heat_return_pipe'].includes($store.state.device.device_type) ?
                (lang === 'ru' ? 'Теплосчетчик' : 'Теплолічильник')
                :
                (lang === 'ru' ? 'Неизвестное устройство' : 'Невідомий пристрій')
              }}
            </td>
            <td class="px-3">{{ lang === 'ru' ? 'Объект' : "Об'єкт" }}:</td>
            <td>
              {{ lang === 'ru' ? $store.state.device.object.group_name : $store.state.device.object.group_name_ua }}
              {{ lang === 'ru' ? $store.state.device.object.name : $store.state.device.object.name_ua }}</td>
          </tr>
          <tr>
            <td class="pr-3">{{ lang === 'ru' ? 'Код производителя' : 'Код виробника' }}:</td>
            <td class="border-right pr-3">{{ $store.state.device.man }}</td>
            <td class="px-3">{{ lang === 'ru' ? 'Помещение' : 'Приміщення'}}:</td>
            <td>№ {{ $store.state.device.apartment_number }}</td>
          </tr>
          <tr>
            <td class="pr-3">{{ lang === 'ru' ? 'Серийный номер' : 'Серійний номер'}}:</td>
            <td class="border-right pr-3">{{ $store.state.device.serial_number }}</td>
            <td class="px-3">{{ lang === 'ru' ? 'Тип помещения' : 'Тип приміщення'}}:</td>
            <td>{{ {'residential': (lang === 'ru' ? 'Жилое' : 'Житлове'), 'commercial': (lang === 'ru' ? 'Коммерческое' : 'Коммерційне')}[$store.state.device.apartment_type] }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="fw-700 fs-14 d-flex flex-wrap align-items-center justify-content-center position-relative">

      <div class="mb-3 mr-3 d-none d-lg-block">
        {{ lang === 'ru' ? 'Начало периода' : 'Початок періоду' }}:
      </div>

      <Datepicker
        v-model="$store.state.date_from"
        :format="customFormatter"
        @input="setDateFrom"
        :disabled-dates="{
          from: $moment().toDate()
        }"
        :language="lang === 'ru' ? ru : ua"
        :monday-first="true"
        input-class="mb-3 form-control bg-transparent border-dark fw-700 fs-14 text-dark mr-3 mr-lg-4"/>

      <div class="mb-3 mr-3 d-none d-lg-block">
        {{ lang === 'ru' ? 'Конец периода' : 'Кінець періоду' }}:
      </div>

      <div class="mb-3 mr-3 d-lg-none">
        -
      </div>

      <Datepicker
        v-model="$store.state.date_to"
        :format="customFormatter"
        :disabled-dates="{
          to: $store.state.date_from,
          from: $moment($store.state.date_from).add(6, 'month').toDate()
        }"
        :language="lang === 'ru' ? ru : ua"
        :monday-first="true"
        input-class="mb-3 form-control bg-transparent border-dark fw-700 fs-14 text-dark mr-3 mr-lg-4"/>

      <div class="mb-3 mr-3">
        {{ lang === 'ru' ? 'Экспортировать в' : 'Експортувати в' }}:
      </div>

      <a
        :href="'/api/report/device/' + $store.state.device.id + '?format=xlsx&lang=' + lang + '&from=' + $moment($store.state.date_from).utc().format() + '&to=' + $moment($store.state.date_to).utc().format()"
        target="_blank"
        class="mb-3 ml-lg-4">
        <img src="/img/excel.png" height="26">
      </a>

      <a
        :href="'/api/report/device/' + $store.state.device.id + '?format=pdf&lang=' + lang + '&from=' + $moment($store.state.date_from).utc().format() + '&to=' + $moment($store.state.date_to).utc().format()"
        target="_blank"
        class="mb-3 ml-3 text-danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
          <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
        </svg>
      </a>

    </div>

    <b-tabs
      :small="$store.state.vw < 992"
      pills
      content-class="mt-3"
      @input="tabChanged">
      <b-tab :title="lang === 'ru' ? 'Таблица' : 'Таблиця'" active>
        <div class="table-responsive">
          <b-table
            id="manager-device-table"
            head-variant="light"
            responsive
            bordered
            small
            :fields="fields"
            :items="$store.state.device.data"
            @sort-changed="sortingChanged">
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(date_time_of_request)="data">
              {{ data.item.date_time_of_request | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template #cell(e)="data">
              {{ new Intl.NumberFormat().format(data.item.e) }}
            </template>
            <template #cell(de)="data">
              {{ new Intl.NumberFormat().format(data.item.de) }}
            </template>
            <template #cell(coast)="data">
              {{ new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'UAH'}).format(data.item.coast || 0) }}
            </template>
            <template #cell(vm3)="data">
              {{ new Intl.NumberFormat().format(data.item.dv) }}
            </template>
            <template #cell(v_origin)="data">
              {{ data.item.v }}
            </template>
            <!-- <template #cell(mt)="data">
              {{ getTemp(data.item.date_time_of_request) }}
            </template> -->

            <template #custom-foot>
              <b-tr>
                <b-th colspan="5" class="text-right">Сумма</b-th>
                <b-th class="text-center">
                  {{ new Intl.NumberFormat().format($store.state.device.data.reduce((a, b) => +a + +(b.de || 0), 0)) }}
                </b-th>
                <b-th class="text-center">
                  {{ new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'UAH'}).format($store.state.device.data.reduce((a, b) => +a + +(b.coast || 0), 0)) }}
                </b-th>
                <b-th colspan="2" class=""></b-th>
                <b-th class="text-center">
                  {{ new Intl.NumberFormat().format($store.state.device.data.reduce((a, b) => +a + +(b.dv || 0), 0)) }}
                </b-th>
                <b-th colspan="6"></b-th>
              </b-tr>
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab :title="$store.state.vw < 992 ? 'Q, Гкал' : (lang === 'ru' ? 'График потребления Q, Гкал' : 'Графік споживання Q, Гкал')">
        <div class="h-300-px mb-5">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getQGraphOptions"
            :key="0"
            :height="300"
            :series="getGraphData">
          </apexchart>
        </div>
        <div class="h-300-px mb-5">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getWeatherGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataWeather">
          </apexchart>
        </div>
        <div class="h-300-px">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getCoastGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataCoast">
          </apexchart>
        </div>
      </b-tab>
      <b-tab :title="$store.state.vw < 992 ? 'V, м³' : (lang === 'ru' ? 'График расхода теплоносителя V, м³' : 'Графік витрати теплоносія V, м³')">
        <div class="h-300-px mb-5">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getVGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataV">
          </apexchart>
        </div>
        <div class="h-300-px">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getWeatherGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataWeather">
          </apexchart>
        </div>
      </b-tab>
      <b-tab :title="$store.state.vw < 992 ? 'T1 и Т2, °C' : (lang === 'ru' ? 'График температур теплоносителя T1 и Т2, °C' : 'Графік температур теплоносія T1 и Т2, °C')">
        <div class="h-300-px mb-5">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getTGraphOptions"
            :key="2"
            :height="300"
            :series="getGraphDataT">
          </apexchart>
        </div>
        <div class="h-300-px">
          <apexchart
            v-if="apexchartVisible"
            type="line"
            :options="getWeatherGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataWeather">
          </apexchart>
        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vdp_translation_ru from  'vuejs-datepicker/dist/locale/translations/ru'
import vdp_translation_ua from  'vuejs-datepicker/dist/locale/translations/uk'

export default {
  name: 'Device',

  components: {
    Datepicker
  },

  data () {
    return {
      apexchartVisible: false,
      ru: vdp_translation_ru,
      ua: vdp_translation_ua,
    }
  },

  watch:{
    $route (to) {
      this.$store.commit('setData', {
        attr: 'device',
        value: {
          id: to.params.id
        }
      })
      this.$store.dispatch('getDeviceData')
    },
    '$store.state.date_from' () {
      this.$store.dispatch('getDeviceData')
    },
    '$store.state.date_to' () {
      this.$store.dispatch('getDeviceData')
    }
  },

  computed: {

    fields () {
      return [
        {
          key: 'index',
          label: '№',
          sortable: false
        },
        {
          label: this.lang === 'ru' ? 'Дата и время опроса устройства' : 'Дата та час опитування пристрою',
          key: 'date_time_of_request',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Q (В единицах устройства)' : 'Q (В одиницях пристрою)',
          key: 'e_origin',
          variant: 'warning',
          tdClass: 'bg-heat',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Q (единиц)' : 'Q (одиниці)',
          key: 'eu',
          tdClass: 'bg-heat',
          sortable: true
        },
        {
          label: 'Q (Гкал)',
          key: 'e',
          tdClass: 'bg-deep-heat',
          sortable: true
        },
        {
          label: 'ΔQ (Гкал)',
          key: 'de',
          tdClass: 'bg-deep-heat',
          sortable: true
        },
        {
          label: 'ΔQ (грн)',
          key: 'coast',
          tdClass: 'bg-deep-heat',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'V (В единицах устройства)' : 'V (В одиницях пристрою)',
          key: 'v',
          tdClass: 'bg-water',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'V (единиц)' : 'V (одиниці)',
          key: 'vu',
          tdClass: 'bg-water',
          sortable: true
        },
        {
          label: 'V (м³)',
          key: 'v',
          tdClass: 'bg-deep-water',
          sortable: true
        },
        {
          label: 'ΔV (м³)',
          key: 'dv',
          tdClass: 'bg-deep-water',
          sortable: true
        },
        {
          label: 'T1 (°C)',
          key: 't1',
          sortable: true
        },
        {
          label: 'T2 (°C)',
          key: 't2',
          sortable: true
        },
        {
          label: 'ΔT (K)',
          key: 'dt',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Ср. сут. темп. воз. (°C)' : 'Ср. доб. темп. пов. (°C)',
          key: 'mt',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Статус устройства' : 'Статус пристрою',
          key: 'status_field',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Ошибки на устройстве' : 'Помилки на пристрої',
          key: 'error_flag',
          sortable: true
        },
      ]
    },

    lang () {
      return this.$store.state.lang
    },

    getMonths () {
      const dates = []
      let start = this.$moment(this.$store.state.date_from).startOf('month')
      console.log('start', this.$store.state.date_from, start.format())
      while (start < this.$store.state.date_to) {
        // console.log('from', from.format(), to.format())
        dates.push({
          name: start.format('MMMM YY'),
          from: start.toDate(),
          to: start.add(1, 'month').toDate(),
        })
      }
      return dates
    },

    getDeviceTitle () {
      return `${this.$store.state.device.serial_number} ${this.$store.state.device.man} ${this.$store.state.device.device_type}`
    },

    getGraphData () {
      // const data = []
      // for (var i = 0; i < this.$store.state.device.data.length - 1; i++) {
      //   const e = this.$store.state.device.data[i].e - this.$store.state.device.data[i + 1].e
      //   data.push(parseFloat(e.toFixed(3)))
      // }
      return [{
        name: `Q`,
        data: this.$store.state.device.data.map(d => parseFloat((d.de || 0).toFixed(3) )).reverse()
      }]
    },

    getGraphDataV () {
      // const data = []
      // for (var i = 0; i < this.$store.state.device.data.length - 1; i++) {
      //   const v = this.$store.state.device.data[i].v - this.$store.state.device.data[i + 1].v
      //   data.push(parseFloat(v.toFixed(3)))
      // }
      return [{
        name: `V`,
        data: this.$store.state.device.data.map(d => parseFloat((d.dv || 0).toFixed(3))).reverse()
      }]
    },

    getGraphDataT () {
      return [
        {
          name: `T1`,
          data: this.$store.state.device.data.map(d => d.t1).reverse()
        },
        {
          name: `T2`,
          data: this.$store.state.device.data.map(d => d.t2).reverse()
        },
        {
          name: `ΔT`,
          data: this.$store.state.device.data.map(d => parseFloat((d.t1 - d.t2).toFixed(2))).reverse()
        }
      ]
    },

    getGraphDataWeather () {
      return [
        {
          name: this.$store.state.lang === 'ru' ? `Среднесуточная температура воздуха` : 'Середньодобова температура повітря',
          data: this.$store.state.device.data.map(d => this.getTemp(d.date_time_of_request)).reverse()
        }
      ]
    },

    getGraphDataCoast () {
      return [
        {
          name: this.$store.state.lang === 'ru' ? `Потребление тепловой энергии, грн` : 'Споживання теплової енергії',
          data: this.$store.state.device.data.map(d => parseFloat(((d.de || 0) * this.$store.state.device.object.heat_tariff).toFixed(2))).reverse()
        }
      ]
    },

    getVGraphOptions () {
      return {
        chart: {
          id: 'vuechart-v',
          height: '100%',
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    },

    getQGraphOptions () {
      return {
        chart: {
          id: 'vuechart-q',
          height: '100%',
        },
        colors: ['#fb881e'],
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    },

    getCoastGraphOptions () {
      return {
        chart: {
          id: 'vuechart-c',
          height: '100%',
        },
        colors: ['#579bff'],
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    },

    getTGraphOptions () {
      return {
        chart: {
          id: 'vuechart-t',
          height: '100%',
        },
        colors: ['#cc2129', '#5ba1e6', '#cc212990'],
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    },

    getWeatherGraphOptions () {
      return {
        chart: {
          id: 'vuechart-w',
          height: '100%',
        },
        colors: ['#21cc46'],
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    },

  },

  methods: {

    tabChanged () {
      this.apexchartVisible = false
      setTimeout(() => {
        this.apexchartVisible = true
      }, 1000)
    },

    sortingChanged (ctx) {
      console.log('ctx.sortBy, ctx.sortDesc', ctx.sortBy, ctx.sortDesc)
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
      // egcal
      // coast
      // vm3
      // if (ctx.sortBy === 'coast') {
      //   const device = { ...this.$store.state.device }
      //   device.data = device.data.sort((a, b) => a.de - b.de)
      //   console.log('device.data[0]', device.data[0])
      // }
      // $store.state.device.data
    },

    getTemp (date) {
      const day = this.$store.state.weather.filter(w => date.includes(w.date))[0]
      if (day) {
        return day.temperature
      } else {
        return ''
      }
    },

    setPeriod (period) {
      console.log('period', period);
      this.$store.commit('setData', {
        attr: 'date_from',
        value: period.from
      })
      this.$store.commit('setData', {
        attr: 'date_to',
        value: period.to
      })
    },

    customFormatter (date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },

    setDateFrom () {
      if (
        this.$moment(this.$store.state.date_to) > this.$moment(this.$store.state.date_from).add(6, 'month') ||
        this.$moment(this.$store.state.date_to) < this.$moment(this.$store.state.date_from)
      ) {
        let to = this.$moment(this.$store.state.date_from).add(6, 'month')
        if (to > this.$moment()) {
          to = this.$moment()
        }
        this.$store.commit('setData', {
          attr: 'date_to',
          value: to.toDate()
        })
      }
    }
  },

  async mounted () {
    try {
      this.$store.commit('mergeStore', {
        device: {
          id: this.$route.params.id
        },
        loading: true
      })
      // this.$store.commit('setData', {
      //   attr: 'date_to',
      //   value: this.$moment().toDate()
      // })
      // this.$store.commit('setData', {
      //   attr: 'date_from',
      //   value: this.$moment().subtract(6, 'month').toDate()
      // })
      this.$store.dispatch('getDeviceData')
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`, {duration: 1000})
    }
  }
}
</script>

<style lang="scss">
#manager-device-table{
  td{
    width: 6.25%;
    text-align: center;
  }
}
</style>
