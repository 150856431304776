<template lang="html">
  <div id="lang-change" class="text-right py-1 pr-4">
    <button
      class="btn btn-link px-2 py-1"
      :class="{
        'text-secondary': $store.state.lang === 'ua',
        'text-ccc': $store.state.lang === 'ru',
      }"
      @click="$store.commit('mergeStore', {lang: 'ua'})">
      Українська
    </button>
    |
    <button
      class="btn btn-link px-2 py-1"
      :class="{
        'text-secondary': $store.state.lang === 'ru',
        'text-ccc': $store.state.lang === 'ua'
      }"
      @click="$store.commit('mergeStore', {lang: 'ru'})">
      Русский
    </button>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.text-ccc{
  color: #ccc;
}
</style>
