<template lang="html">
  <div
    class="lds-dual-ring"
    :style="{
      left: getLeft
    }">
  </div>
</template>

<script>
export default {
  computed: {
    getLeft () {
      let left = this.$store.state.vw / 2 - 40
      if (this.$store.state.vw > 992) {
        left += 150
      }
      console.log('left', left);
      return left + 'px'
    }
  }
}
</script>

<style lang="css" scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 1;
  top: calc(50vh - 40px);
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid var(--primary);
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
