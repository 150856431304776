<template lang="html">
  <div class="h4 text-center py-5">
    Данный функционал находится на этапе разработки
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
