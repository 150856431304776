<template lang="html">
  <div class="p-4 row">

    <div class="col-lg-6 mb-4" v-for="(fieldset, i) in fieldsets[$store.state.lang]" :key="i">

      <div class="fs-14 fw-700 px-4 py-2 bg-light border-bottom border-dark">
        {{ fieldset.name }}
      </div>

      <div v-for="(field, i) in fieldset.fields" :key="i" class="pt-4 px-4 border-bottom border-secondary d-flex">
        <div class="mr-auto d-flex align-items-center">
          {{ field.name }}:
          <span v-if="$store.state.user_edit_field === field.attr">
            <span v-if="field.customer">
              <b-form-select v-if="field.variants" v-model="$store.state.user.customer[field.attr]" :options="field.variants"></b-form-select>
              <input v-else class="form-control border-0" type="text" v-model="$store.state.user.customer[field.attr]">
            </span>
            <input v-else class="form-control border-0" type="text" v-model="$store.state.user[field.attr]">
          </span>
          <span v-else class="pl-2 ml-1">
            <span v-if="field.customer">
              <span v-if="field.variants">
                {{ field.values[user.customer[field.attr]] }}
              </span>
              <span v-else>
                {{ user.customer[field.attr] }}
              </span>
            </span>
            <span v-else>
              {{ user[field.attr] }}
            </span>
          </span>
        </div>
        <button v-if="$store.state.user_edit_field !== field.attr" class="btn btn-link" @click="$store.commit('setData', {attr: 'user_edit_field', value: field.attr})">
          {{ t.change }}
        </button>
        <button v-if="$store.state.user_edit_field === field.attr" @click="$store.dispatch('updateAccount')" class="btn btn-link">
          {{ t.save }}
        </button>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  computed: {
    t () {
      return this.i18n[this.$store.state.lang]
    },
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      i18n: {
        ru: {
          change: 'Изменить',
          save: 'Сохранить',
        },
        ua: {
          change: 'Змінити',
          save: 'Зберегти',
        }
      },
      fieldsets: {
        ru: [
          {
            name: 'Личные данные',
            fields: [
              {name: 'Фамилия', attr: 'last_name'},
              {name: 'Имя', attr: 'first_name'},
              {name: 'Отчество', attr: 'patronymic', customer: true},
              {name: 'Название организации', attr: 'company_name', customer: true},
              {
                name: 'Язык аккаунта',
                attr: 'lang',
                customer: true,
                variants: [
                  { text: 'Русский', value: 'ru' },
                  { text: 'Украинский', value: 'ua' },
                ],
                values: {
                  ua: 'Украинский',
                  ru: 'Русский'
                }
              },
            ],
          },
          {
            name: 'Контакты для оповещения',
            fields: [
              {name: 'Моб. телефон', attr: 'phone', customer: true},
              {name: 'Телеграм', attr: 'telegram', customer: true},
              {name: 'E-mail', attr: 'email'},
            ]
          }
        ],
        ua: [
          {
            name: 'Особисті дані',
            fields: [
              {name: 'Прізвище', attr: 'last_name'},
              {name: "Ім'я", attr: 'first_name'},
              {name: 'По-батькові', attr: 'patronymic', customer: true},
              {name: 'Назва організації', attr: 'company_name', customer: true},
              {
                name: 'Мова облікового запису',
                attr: 'lang',
                customer: true,
                variants: [
                  { text: 'Русский', value: 'ru' },
                  { text: 'Український', value: 'ua' },
                ],
                values: {
                  ua: 'Український',
                  ru: 'Русский'
                }
              },
            ],
          },
          {
            name: 'Контакти для оповіщення',
            fields: [
              {name: 'Моб. телефон', attr: 'phone', customer: true},
              {name: 'Телеграм', attr: 'telegram', customer: true},
              {name: 'E-mail', attr: 'email'},
            ]
          }
        ]
      },
    }
  }
}
</script>

<style lang="css" scoped>
</style>
