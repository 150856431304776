<template>
  <div class="p-3 px-lg-4">
    <h5 class="mb-3">{{ $store.state.user.group.name }}</h5>
    <div class="d-flex flex-wrap">
      <div
        v-for="object in $store.state.objects"
        :key="object.id"
        class="device-card mx-2">
        <div class="card mb-4 border-0">
          <div class="card-header px-4 fs-14 fw-500 border-0 position-relative">
            <div class="position-absolute top-0 right-0">
              <button class="btn btn-sm py-1" @click="copy($event, object)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg>
              </button>
              <br>
              <button class="btn btn-sm py-1" @click="$toasted.error($store.state.i18n[lang].notifDeveloped)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
              </button>
            </div>
            <div class="">
              <router-link :to="{name: 'Object', params: {id: object.id}}" class="text-reset hover-no-decoration">
                <span v-if="object.desc">{{ lang === 'ru' ? object.desc : object.desc_ua }} - </span>{{ lang === 'ru' ? object.group_name : object.group_name_ua }}
              </router-link>
            </div>
            <div class="">
              {{ t.address }}:
            </div>
            <div class="">
              {{ lang === 'ru' ? object.name : object.name_ua }}
            </div>
          </div>
          <div class="card-body fs-14 p-4">
            <div class="fw-700">{{ i18n.devices[lang] }}:</div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.sum_q[lang] }}:</div>
              <div class="col-4">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.e_total) }} Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.start_month_q[lang] }}:</div>
              <div class="col-4">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.e_month) }} Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.sum_v[lang] }}:</div>
              <div class="col-4">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.v_total) }} м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.start_month_v[lang] }}:</div>
              <div class="col-4">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.v_month) }} м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.system_devices[lang] }}:</div>
              <div class="col-4">{{ object.device__count }}</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.not_resp_devices[lang] }}:</div>
              <div class="col-4">{{ object.device_noanswer__count }}</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.error_devices[lang] }}:</div>
              <div class="col-4">0</div>
            </div>
            <hr class="my-3 border-black">
            <div class="fw-700">{{ i18n.heatmeters[lang] }}:</div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.sum_q[lang] }}:</div>
              <div class="col-4">- Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.start_month_q[lang] }}:</div>
              <div class="col-4">- Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.sum_v[lang] }}:</div>
              <div class="col-4">- м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.start_month_v[lang] }}:</div>
              <div class="col-4">- м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.system_devices[lang] }}:</div>
              <div class="col-4">0</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.not_resp_devices[lang] }}:</div>
              <div class="col-4">0</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-8">{{ i18n.error_devices[lang] }}:</div>
              <div class="col-4">0</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Objects',

  data () {
    return {
      i18n: {
        devices: {ru: 'Квартирные теплосчетчики', ua: 'Квартирні теплолічильники',},
        heatmeters: {ru: 'Домовые теплосчетчики', ua: 'Будинкові теплолічильники',},
        sum_q: {ru: 'Суммарно Q по всем устройствам', ua: 'Сумарно Q по всіх пристроях',},
        start_month_q: {ru: 'С начала месяца Q', ua: 'З початку місяця Q',},
        sum_v: {ru: 'Суммарно V', ua: 'Сумарно V',},
        start_month_v: {ru: 'С начала месяца V', ua: 'З початку місяця V',},
        system_devices: {ru: 'Устройств в доме подкл. к системе', ua: 'Пристроїв в будинку підкл. до системи',},
        not_resp_devices: {ru: 'Не отвечающие устройства', ua: 'Не відповідаючі пристрої',},
        error_devices: {ru: 'Устройств в доме с ошибкой', ua: 'Пристрої в будинку з помилкою',},
        ru: {
          address: 'по адресу',
          devices: 'Домовые теплосчетчики',
          heatmeters: 'Домовые теплосчетчики',
          sum_q: 'Суммарно Q по всем устройствам',
          start_month_q: 'С начала месяца Q',
          sum_v: 'Суммарно V',
          start_month_v: 'С начала месяца V',
          system_devices: 'Устройств в доме подкл. к системе',
          not_resp_devices: 'Не отвечающие устройства',
          error_devices: 'Устройств в доме с ошибкой',
        },
        ua: {
          address: 'за адресою',
          devices: 'Будинкові теплолічильники',
          heatmeters: 'Будинкові теплолічильники',
          sum_q: 'Сумарно Q по всіх пристроях',
          start_month_q: 'З початку місяця Q',
          sum_v: 'Сумарно V',
          start_month_v: 'З початку місяця V',
          system_devices: 'Пристроїв в будинку підкл. до системи',
          not_resp_devices: 'Не відповідаючі пристрої',
          error_devices: 'Пристрої в будинку з помилкою',
        }
      }
    }
  },

  computed: {
    lang () {
      return this.$store.state.lang
    },
    t () {
      return this.i18n[this.lang]
    }
  },

  methods: {
    copy (event, object) {
      event.preventDefault()
      let text = ''
      const t = this.i18n[this.lang]
      if (this.lang === 'ru') {
        text += `${object.desc} - ${object.group_name}\n`
        text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
        text += `${t.address}: ${object.name}\n`
      } else {
        text += `${object.desc_ua} - ${object.group_name_ua}\n`
        text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
        text += `${t.address}: ${object.name_ua}\n`
      }
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.devices}\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.sum_q}: ${ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.e_total) } Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.start_month_q}: ${ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.e_month) } Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.sum_v}: ${ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.v_total) } м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.start_month_v}: ${ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(object.stat.v_month) } м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.system_devices}: ${ object.stat.devices_count }\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.not_resp_devices}: 0\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.error_devices}: 0\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.heatmeters}: 0\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.sum_q}: - Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.start_month_q}: - Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.sum_v}: - м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.start_month_v}: - м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.system_devices}: 0\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${t.error_devices}: 0\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'

      this.$toasted.info(this.$store.state.i18n[this.lang].notifCopy)
      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData("Text", text);
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  },

  async mounted () {
    try {
      this.$store.commit('setData', {attr: 'loading', value: true})
      await this.$store.dispatch('getObjects')
      this.$store.commit('setData', {attr: 'loading', value: false})
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`, {duration: 1000})
    }
  }
}
</script>

<style media="screen" lang="scss">

</style>
