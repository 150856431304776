<template>
  <div id="app">
    <Loader v-if="$store.state.loading"/>
    <div v-if="$store.state.user.id">
      <div v-if="$store.state.user.is_superuser">
        <b-navbar type="dark" variant="dark">
          <b-navbar-nav>
            <b-nav-item to="/manage/groups" exact-active-class="exact">Группы</b-nav-item>
            <b-nav-item to="/manage/objects" exact-active-class="exact">Объекты</b-nav-item>
            <b-nav-item to="/manage/apartments" exact-active-class="exact">Помещения</b-nav-item>
            <b-nav-item to="/manage/devices" exact-active-class="exact">Счетчики</b-nav-item>
            <b-nav-item to="/manage/accounts" exact-active-class="exact">Аккаунты</b-nav-item>
            <b-nav-item to="/manage/parser" exact-active-class="exact">Парсер</b-nav-item>
          </b-navbar-nav>
        </b-navbar>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <main v-else>
        <Sidebar class="d-none d-lg-flex"/>
        <div class="content d-flex flex-column">
          <Navbar class="d-lg-none"/>
          <Lang class="d-none d-lg-block"/>
          <router-view></router-view>
          <Footer/>
        </div>
      </main>
    </div>
    <div v-else class="d-flex flex-column align-items-center mh-100vh">
      <Lang class="align-self-end"/>
      <AuthCard/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import AuthCard from './components/Auth'
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Lang from './components/Lang'
import Loader from './components/Loader'

export default {
  name: 'App',

  components: {
    AuthCard,
    Sidebar,
    Footer,
    Loader,
    Navbar,
    Lang,
  },

  async mounted () {
    try {
      this.$store.dispatch('getUser')
      this.$store.dispatch('getWeather')
      this.$store.commit('setData', {attr: 'vw', value: window.innerWidth})
      window.addEventListener('resize', () => {
        this.$store.commit('setData', {attr: 'vw', value: window.innerWidth})
      })
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss">
$nav-link-padding-y: .75rem;
$nav-pills-link-active-bg: rgb(5, 54, 127);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);
$primary: #0083FF;
$warning: rgb(227, 126, 51);

$table-border-color: rgb(19, 2, 10);
$table-head-color: #000;
$table-th-font-weight: 600;

$btn-link-disabled-color: #E3E3E3;

$form-group-margin-bottom: 1.25rem;

@import '~bootstrap/scss/bootstrap';
@import '@/assets/extra';
@import '@/assets/main';

.table-danger {
  td{
      border-color: #000000;
  }
}

.btn:focus{
  box-shadow: none;
}

.content{
  min-height: 100vh;
}
</style>
