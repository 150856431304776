<template>
  <b-card id="auth-card" header-tag="nav" class="my-auto p-1 mb-4" v-if="$store.state.lang">

    <div v-if="$store.state.auth === 'login'">
      <b-form @submit.prevent="$store.dispatch('login')" id="login-form" class="mb-3">
        <b-form-group id="input-group-1" label-for="input-auth-login">
          <b-form-input
            id="input-auth-login"
            v-model="$store.state.user.username"
            :placeholder="t.login"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label-for="input-auth-password" class="mb-4 pb-4">
          <b-form-input
            id="input-auth-password"
            v-model="$store.state.user.password"
            :placeholder="t.password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>

        <b-button id="input-auth-submit" type="submit" variant="primary" size="lg" block>
          {{ t.enter }}
        </b-button>
      </b-form>
      <div class="text-center mb-4">
        <button
          id="auth-signup-btn-one"
          class="btn btn-link py-0 fs-18"
          @click="$store.commit('setData', {attr: 'auth', value: 'signup_one'}); $store.dispatch('getGroups')">
          {{ t.signup_title }}
        </button>
        <button
          id="auth-password-recovery-btn"
          class="btn btn-link py-0 fs-18"
          @click="$store.commit('setData', {attr: 'auth', value: 'password_recovery'})">
          {{ t.recovery }}
        </button>
      </div>
    </div>

    <div v-else-if="$store.state.auth === 'signup_one' || $store.state.auth === 'signup_two'">
      <div :class="{'d-none': $store.state.auth === 'signup_two'}">
        <b-form-group label-for="signup-username-input">
          <b-form-input
            id="signup-username-input"
            v-model="$store.state.user.username"
            :placeholder="t.login"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-email-input">
          <b-form-input
            id="signup-email-input"
            v-model="$store.state.user.email"
            placeholder="Email"
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-phone-input">
          <b-form-input
            id="signup-phone-input"
            v-model="$store.state.user.customer.phone"
            :placeholder="t.phone"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-last_name-input">
          <b-form-input
            id="signup-last_name-input"
            v-model="$store.state.user.last_name"
            :placeholder="t.lastname"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-first_name-input">
          <b-form-input
            id="signup-first_name-input"
            v-model="$store.state.user.first_name"
            :placeholder="t.firstname"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-patronymic-input">
          <b-form-input
            id="signup-patronymic-input"
            v-model="$store.state.user.customer.patronymic"
            :placeholder="t.patronymic"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-password-input">
          <b-form-input
            id="signup-password-input"
            v-model="$store.state.user.password"
            :placeholder="t.password"
            type="password"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="signup-password-confirm-input">
          <b-form-input
            id="signup-password-confirm-input"
            v-model="$store.state.user.password_confirm"
            :placeholder="t.password_confirm"
            type="password"
          ></b-form-input>
        </b-form-group>

        <span
          id="auth-signup-btn-two"
          class="btn btn-primary btn-block btn-lg"
          @click="signupNextStep">
          {{ t.next }}
        </span>

      </div>

      <div :class="{'d-none': $store.state.auth === 'signup_one'}">
        <b-form-group>
          <v-select
            :placeholder="t.select_group"
            v-model="$store.state.user.customer.group_id"
            :options="$store.state.groups"
            :reduce="group => group.id"
            :getOptionLabel="group => lang === 'ru' ? group.name : group.name_ua"
            @input="
              $store.commit('setData', {attr: 'user', value: { ...$store.state.user, customer: { ...$store.state.user.customer, object_id: null, apartment_id: null }}});
              $store.commit('setData', {attr: 'apartments', value: []});
              $store.commit('setData', {attr: 'objects', value: []});
              $store.dispatch('getObjects')
            ">
          </v-select>
        </b-form-group>

        <b-form-group>
          <v-select
            :placeholder="t.select_home"
            v-model="$store.state.user.customer.object_id"
            :options="$store.state.objects"
            :reduce="object => object.id"
            :getOptionLabel="
              object => lang === ru
              ?
              `${object.group_name}, ${object.name}`
              :
              `${object.group_name_ua}, ${object.name_ua}`
            "
            @input="
              $store.commit('setData', {attr: 'user', value: { ...$store.state.user, customer: { ...$store.state.user.customer, apartment_id: null }}});
              $store.commit('setData', {attr: 'apartments', value: []});
              $store.dispatch('getApartments')
            ">
          </v-select>
        </b-form-group>

        <b-form-group>
          <v-select
            :placeholder="t.select_appartment"
            :options="$store.state.apartments"
            :reduce="apartment => apartment.id"
            :getOptionLabel="
              apartment => `${
                apartment.apartment_type === 'residential'
                ?
                `№ ${lang === 'ru' ? apartment.apartment_number : apartment.apartment_number_ua} ${t.residential}`
                :
                `${lang === 'ru' ? apartment.apartment_number : apartment.apartment_number_ua} ${t.commercial}`}`
            "
            v-model="$store.state.user.customer.apartment_id">
          </v-select>
        </b-form-group>

        <b-form-group class="mb-5" id="file-form-group" :description="t.upload_photo_desc">
          <b-form-file
            v-model="$store.state.user_file"
            browse-text="Выбрать"
            :placeholder="t.upload_photo">
          </b-form-file>
        </b-form-group>

        <b-button
          @click="signup"
          id="signup-submit" variant="primary" size="lg" block>
          {{ t.signup }}
        </b-button>

      </div>

      <div class="text-center">
        <button
          class="btn btn-link fs-18 py-0"
          @click="$store.commit('setData', {attr: 'auth', value: $store.state.auth === 'signup_two' ? 'signup_one' : 'login'})">
          {{ t.back }}
        </button>
      </div>
    </div>

    <div v-else-if="$store.state.auth === 'password_recovery'">
      <b-form @submit.prevent="$store.dispatch('passwordRecovery')" id="password_recovery-form" class="mb-3">
        <b-form-group>
          <b-form-input
            id="input-password_recovery-email"
            v-model="$store.state.user.email"
            type="email"
            placeholder="Email"
            required
          ></b-form-input>
        </b-form-group>
        <p>{{ t.recovery_desc }}</p>
        <b-button id="password_recovery-submit" type="submit" variant="primary" size="lg" block>Отправить новый пароль</b-button>
      </b-form>
      <div class="text-center">
        <button
          id="auth-signup-btn-one"
          class="btn btn-link pb-0 fs-18"
          @click="$store.commit('setData', {attr: 'auth', value: 'login'})">
          {{ t.back }}
        </button>
      </div>
    </div>

  </b-card>

</template>

<script>

export default {
  name: 'Auth',
  data () {
    return {
      i18n: {
        ru: {
          signup_title: 'Регистрация нового аккаунта',
          recovery: 'Восстановление пароля',
          enter: 'Вход',
          login: 'Логин',
          phone: 'Номер мобильного телефона',
          lastname: 'Фамилия',
          firstname: 'Имя',
          patronymic: 'Отчество',
          password: 'Пароль',
          password_confirm: 'Пароль еще раз',
          next: 'Дальше',
          back: 'Назад',
          select_group: 'Выберите ЖК',
          select_home: 'Выберите дом',
          select_appartment: 'Выберите помещение',
          upload_photo: 'Загрузите фото проекта',
          upload_photo_desc: 'Размер фото не должен привышать 10 МБ',
          signup: 'Регистрация',
          recovery_desc: 'Введите, пожалуйста, email, который был указан при регистрации аккаунта, на него будет отправлено письмо с новым паролем.',
          residential: 'Жилое',
          commercial: 'Коммерческое',
        },
        ua: {
          signup_title: 'Реєстрація нового акаунту',
          recovery: 'Відновлення паролю',
          enter: 'Вхід',
          login: 'Логін',
          phone: 'Номер мобільного телефону',
          lastname: 'Прізвище',
          firstname: "Ім'я",
          patronymic: 'По батькові',
          password: 'Пароль',
          password_confirm: 'Пароль ще раз',
          next: 'Далі',
          back: 'Назад',
          select_group: 'Виберіть ЖК',
          select_home: 'Виберіть будинок',
          select_appartment: 'Виберіть приміщення',
          upload_photo: 'Завантажте фото проекту',
          upload_photo_desc: 'Розмір фото не повинен перевищувати 10 МБ',
          signup: 'Реєстрація',
          recovery_desc: 'Введіть, будь ласка, email, який був вказаний при реєстрації облікового запису, на нього буде надіслано листа з новим паролем.',
          residential: 'Житлове',
          commercial: 'Коммерційне',
        }
      }
    }
  },

  computed: {
    lang () {
      return this.$store.state.lang
    },
    t () {
      return this.i18n[this.$store.state.lang]
    }
  },

  methods: {

    signupNextStep () {
      if (!this.$store.state.user.username) {
        return this.$toasted.error('Необходимо заполнить поле логин')
      }
      if (!this.$store.state.user.email) {
        return this.$toasted.error('Необходимо заполнить поле email')
      }
      if (!this.$store.state.user.customer.phone) {
        return this.$toasted.error('Необходимо заполнить номер телефона')
      }
      if (!this.$store.state.user.first_name) {
        return this.$toasted.error('Необходимо заполнить поле имя')
      }
      if (!this.$store.state.user.last_name) {
        return this.$toasted.error('Необходимо заполнить поле фамилия')
      }
      if (!this.$store.state.user.password) {
        return this.$toasted.error('Необходимо заполнить поле пароль')
      }
      if (!this.$store.state.user.password_confirm) {
        return this.$toasted.error('Необходимо заполнить поле подтверждение пароля')
      }
      if (this.$store.state.user.password !== this.$store.state.user.password_confirm) {
        return this.$toasted.error('Пароль и подтверждение пароля не совпадают')
      }
      this.$store.commit('setData', {attr: 'auth', value: 'signup_two'})
    },

    signup () {
      if (!this.$store.state.user.customer.group_id) {
        return this.$toasted.error('Необходимо привязать ЖК')
      }
      if (!this.$store.state.user.customer.object_id) {
        return this.$toasted.error('Необходимо привязать дом')
      }
      if (!this.$store.state.user.customer.apartment_id) {
        return this.$toasted.error('Необходимо привязать квартиру/помещение')
      }
      this.$store.dispatch('signup')
    }
  }
}
</script>


<style lang="scss">
#auth-card{
  width: 377px;
  border: 0.1px solid #FAFBFC;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  input{
    &:not([type='search']){
      border: 1px solid #BEBEBE;
    }

    background: rgba(250, 250, 250, 0.5);
    border-radius: 2px;
    &::placeholder{
      font-size: 18px;
      color: #A5A5A5;
    }

  }

  .v-select{
    .vs__dropdown-toggle{
      padding: 4px;
      border-radius: 2px;
      .vs__actions{
        display: none;
      }
    }
    .vs__dropdown-menu{
      min-width: 100% !important;
      width: fit-content !important;
      background: none;
      box-shadow: none;
      border: none;
      padding: 0;
      .vs__dropdown-option, .vs__no-options{
        font-size: 18px;
        color: black;
        background: #FAFAFA;
        border: 1px solid #BEBEBE;
        border-radius: 2px;
        padding: 6px;
        //  vs__dropdown-option--selected vs__dropdown-option--highlight
        &:hover{
          background: #dbdada;
        }
      }
    }
  }

  .form-file-text{
    font-size: 18px;
    color: #A5A5A5;
  }

  #input-auth-submit, #auth-signup-btn-two, #signup-submit, #password_recovery-submit{
    background: #0052CC;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
  }

  button.btn-link:focus{
    box-shadow: none;
  }
}

footer{
  left: calc(50% - 140px) !important;
}

#file-form-group{
  small{
    font-weight: bold;
  }
}

</style>
