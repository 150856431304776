<template lang="html">
  <div class="">
    <Objects v-if="$store.state.user.customer.manager"/>
    <Devices v-else-if="$store.state.user.id"/>
  </div>
</template>

<script>
import Objects from './Objects'
import Devices from './Devices'
export default {
  components: {
    Objects,
    Devices
  }
}
</script>


<style lang="css" scoped>
</style>
