<template lang="html">
  <div class="pt-3">
    <div class="d-flex mb-4">
      <h3 class="mr-4">Парсер</h3>
      <button
        class="btn btn-primary"
        :disabled="parser_running"
        @click="runParser">
        {{ parser_running ? 'В процессе ...' : 'Запустить' }}
      </button>
    </div>
    <form ref="form" @submit.stop.prevent="$store.dispatch('saveConfig')">
      <div class="row">
        <div class="col-2">
          <input type="time" v-model="$store.state.config.parse_time" class="form-control">
        </div>
        <div class="col">
          <button type="submit" class="btn btn-secondary">Сохранить</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ManageParser',
  data: () => {
    return {
      parser_running: false
    }
  },
  methods: {
    runParser () {
      this.$store.dispatch('runParser')
      this.parser_running = true
    }
  },
  mounted () {
    this.$store.dispatch('getConfig')
  }
}
</script>

<style lang="css" scoped>
</style>
