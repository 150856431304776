<template lang="html">
  <div class="pt-3">
    <div class="d-flex">
      <h3>Объекты</h3>
      <div class="mx-auto d-flex">
        <div class="w-300-px mr-3">
          <v-select
            placeholder="Группа"
            v-model="$store.state.objects_filter.group_id"
            :options="$store.state.groups"
            :reduce="group => group.id"
            :getOptionLabel="group => group.name"
            @input="$store.dispatch('getManageObjects')">
          </v-select>
        </div>
      </div>
      <div class="ml-auto">
        <button class="btn btn-light"
          @click="$store.commit('setData', {attr: 'object', value: {}}); $bvModal.show('itemModal')">
          Добавить
        </button>
      </div>
    </div>
    <b-table
      head-variant="light"
      responsive
      small
      bordered
      :fields="fields"
      :items="$store.state.objects">
      <template #cell(btn)="data">
        <b-button
          variant="light"
          @click="$store.commit('setData', {attr: 'object', value: data.item}); $bvModal.show('itemModal')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </b-button>
        <button
          class="btn btn-light"
          @click="$store.commit('setData', {attr: 'object', value: data.item}); $store.dispatch('deleteObject')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </template>
    </b-table>

    <b-pagination
      v-model="$store.state.objects_filter.page"
      :total-rows="$store.state.total_objects"
      :per-page="50"
      @page-click="pageClick">
    </b-pagination>

    <b-modal id="itemModal" hide-footer title="Объект">
      <form ref="form" @submit.stop.prevent="$store.dispatch('saveObject')">

        <b-form-group
          label="Группа"
          label-for="group-select"
          invalid-feedback="Группа обязательно">
          <b-form-select
            required
            v-model="$store.state.object.group_id"
            :options="$store.state.groups.map(g => {return {value: g.id, text: g.name}})">
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Название"
          label-for="name-input"
          invalid-feedback="Название обязательно">
          <b-form-input
            id="name-input"
            v-model="$store.state.object.name"
            required>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Название, укр"
          label-for="name_ua-input"
          invalid-feedback="Название обязательно">
          <b-form-input
            id="name_ua-input"
            v-model="$store.state.object.name_ua"
            required>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание"
          label-for="desc-input">
          <b-form-input
            id="desc-input"
            v-model="$store.state.object.desc">
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание, укр"
          label-for="desc_ua-input">
          <b-form-input
            id="desc_ua-input"
            v-model="$store.state.object.desc_ua">
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Тепловой тариф"
          label-for="heat_tariff-input">
          <b-form-input
            id="heat_tariff-input"
            number
            v-model="$store.state.object.heat_tariff">
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Создание помещений"
          description="Добавляет помещения в объект, формат - 1-10-r-40-60 (от 1 до 10 номера, жилые (r/c), жилая площадь - 40, общая - 60)"
          label-for="extra-input">
          <b-form-input
            id="extra-input"
            v-model="$store.state.object.extra">
          </b-form-input>
        </b-form-group>

        <button type="submit" class="btn btn-primary">Сохранить</button>
      </form>
    </b-modal>

  </div>
</template>

<script>
export default {
  name: 'ManageObjects',
  data: () => {
    return {
      fields: [
        {label: 'Группа', key: 'group_name'},
        {label: 'Группа, укр', key: 'group_name_ua'},
        {label: 'Название', key: 'name'},
        {label: 'Название, укр', key: 'name_ua'},
        {label: 'Описание', key: 'desc'},
        {label: 'Описание, укр', key: 'desc_ua'},
        {label: 'Тариф тепловой', key: 'heat_tariff'},
        {label: '', key: 'btn', th_class: 'text-right'},
      ]
    }
  },
  methods: {
    pageClick () {
      setTimeout(() => {
        this.$store.dispatch('getManageObjects')
      }, 100)
    },
  }
}
</script>

<style lang="css" scoped>
.w-300-px{
  width: 300px;
}
</style>
