<template lang="html">
  <div class="py-3">
    <div class="d-flex">
      <h3>Счетчики</h3>

      <div class="mx-auto d-flex align-items-center">
        <b-form-input
          v-model="$store.state.devices_filter.serial_number_from"
          class="mr-2"
          placeholder="Серийный номер от"
          @input="$store.dispatch('getManageDevices')">
        </b-form-input>
        <b-form-input
          v-model="$store.state.devices_filter.serial_number_to"
          class="mr-2"
          placeholder="Серийный номер до"
          @input="$store.dispatch('getManageDevices')">
        </b-form-input>
        <b-form-input
          v-model="$store.state.devices_filter.man"
          class="mr-2"
          placeholder="Производитель"
          @input="$store.dispatch('getManageDevices')">
        </b-form-input>
        <div class="mr-2">
          <v-select
            class="w-200-px"
            placeholder="Тип"
            v-model="$store.state.devices_filter.device_type"
            :options="['heat_flow_pipe', 'heat_return_pipe', 'water', 'warm_water', 'hot_water', 'cold_water', 'bus_or_system_component', 'reserved_or_unknown_device', 'other']"
            @input="$store.dispatch('getManageDevices')">
          </v-select>
        </div>
        <b-form-checkbox
          v-model="$store.state.devices_filter.ne_mcl_id"
          :value="true"
          :unchecked-value="false"
          @input="$store.dispatch('getManageDevices')">
          Не
        </b-form-checkbox>
        <b-form-input
          v-model="$store.state.devices_filter.mcl_id"
          class="mr-2"
          placeholder="Mcl"
          @input="$store.dispatch('getManageDevices')">
        </b-form-input>
        <div class="mr-2">
          <v-select
            class="w-200-px"
            placeholder="Статус"
            v-model="$store.state.devices_filter.device_status"
            :options="['new', 'active', 'noanswer', 'ignored']"
            @input="$store.dispatch('getManageDevices')">
          </v-select>
        </div>
        <div class="mr-2">
          <v-select
            class="w-200-px"
            placeholder="Привязка"
            v-model="$store.state.devices_filter.linked"
            :options="['linked', 'unlinked']"
            @input="$store.dispatch('getManageDevices')">
          </v-select>
        </div>

      </div>

      <div class="">
        <button class="btn btn-light"
          @click="$store.commit('setData', {attr: 'device', value: {}}); $bvModal.show('itemModal')">
          Добавить
        </button>
      </div>
    </div>
    <b-table
      head-variant="light"
      small
      responsive
      bordered
      :fields="fields"
      :items="$store.state.devices">
      <template #head(serial_number)>
        <Th name="Серийный номер" field="serial_number"/>
      </template>
      <template #head(man)>
        <Th name="Производитель" field="man"/>
      </template>
      <template #head(firmware_ver)>
        <Th name="Версия ПО" field="firmware_ver"/>
      </template>
      <template #head(mcl_id)>
        <Th name="Mcl" field="mcl_id"/>
      </template>
      <template #head(date_time_of_last_answer)>
        <Th name="Дата последнего опроса" field="date_time_of_last_answer"/>
      </template>
      <template #head(linked)>
        <Th name="Привязан" field="linked"/>
      </template>
      <template #cell(btn)="data">
        <b-button
          variant="light"
          title="Редактировать"
          @click="
            $store.commit('setData', {attr: 'device', value: data.item});
            $bvModal.show('itemModal');
            changeObject()
          ">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </b-button>
        <button
          class="btn btn-light"
          title="Удалить"
          @click="$store.commit('setData', {attr: 'device', value: data.item}); $store.dispatch('deleteDevice')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
        <button
          class="btn btn-light"
          title="Отвязать"
          @click="$store.commit('setData', {attr: 'device', value: data.item}); $store.dispatch('unlinkDevice')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </button>
      </template>
    </b-table>

    <div class="d-flex align-items-center">
      <b-pagination
        v-model="$store.state.devices_filter.page"
        :total-rows="$store.state.total_devices"
        :per-page="50"
        class="mb-0"
        @page-click="pageClick">
      </b-pagination>

      <div class="ml-3">
        Всего устройств {{ $store.state.total_devices }}
      </div>
    </div>

    <b-modal
      id="itemModal"
      size="xl"
      hide-footer
      :no-close-on-backdrop="true"
      title="Счетчик">

      <b-form-group
        label="Серийный номер"
        label-for="serial_number-input"
        invalid-feedback="Номер обязательно">
        <b-form-input
          id="serial_number-input"
          number
          v-model="$store.state.device.serial_number"
          required>
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Производитель"
        label-for="man-input"
        invalid-feedback="Производитель обязательно">
        <b-form-input
          id="man-input"
          v-model="$store.state.device.man"
          required>
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Группа">
        <v-select
          required
          placeholder="Группа"
          v-model="$store.state.device.group_id"
          @input="changeGroup()"
          :options="$store.state.groups"
          :reduce="group => group.id"
          :getOptionLabel="group => group.name">
        </v-select>
      </b-form-group>

      <b-form-group
        label="Объект">
        <v-select
          required
          placeholder="Объект"
          v-model="$store.state.device.object_id"
          @input="changeObject()"
          :options="filterObjects"
          :reduce="object => object.id"
          :getOptionLabel="object => `${object.group_name}, ${object.name}`">
        </v-select>
      </b-form-group>

      <b-form-group
        label="Тип">
        <b-form-select
          v-model="$store.state.device.apartment_type"
          :options="['residential', 'commercial']">
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Привязка">
        <b-form-select
          v-model="linked"
          :options="['linked', 'unlinked']">
        </b-form-select>
      </b-form-group>

      <b-form-group>
        <label>
          Помещение {{ $store.state.device.apartment_number }}
        </label>
        <v-select
          required
          placeholder="Помещение"
          v-model="$store.state.device.apartment_id"
          :options="filterApartments"
          :reduce="apartment => apartment.id"
          :getOptionLabel="apartment => `${apartment.apartment_number}, ${apartment.apartment_type}`">
        </v-select>
      </b-form-group>

      <button
        v-if="$store.state.device.id"
        @click="linkDevice"
        class="btn btn-primary mr-2">
        Привязать
      </button>
      <button
        @click="linkNextDevice"
        class="btn btn-success">
        Привязать следующий
      </button>
    </b-modal>

  </div>
</template>

<script>
import Th from './Th'
export default {
  name: 'ManageObjects',
  components: {
    Th
  },
  data: () => {
    return {
      linked: null,
      sortBy: null,
      sortDesc: null,
      fields: [
        {label: 'Серийный номер', key: 'serial_number'},
        {label: 'Производитель', key: 'man'},
        {label: 'Тип', key: 'device_type'},
        {label: 'Версия ПО', key: 'firmware_ver'},
        {label: 'Статус', key: 'device_status'},
        {label: 'Mcl', key: 'mcl_id'},
        {label: 'Дата последнего опроса', key: 'date_time_of_last_answer'},
        {label: 'Привязан', key: 'linked'},
        {label: '', key: 'btn', th_class: 'text-right'},
      ]
    }
  },
  computed: {
    filterObjects () {
      if (this.$store.state.device.group_id) {
        return this.$store.state.objects.filter(o => o.group_id === this.$store.state.device.group_id)
      }
      return [] // this.$store.state.objects
    },
    filterApartments () {
      let apartments = this.$store.state.apartments
      if (this.$store.state.device.apartment_type) {
        apartments = apartments.filter(a => a.apartment_type === this.$store.state.device.apartment_type)
      }
      if (this.linked == 'linked') {
        apartments = apartments.filter(a => a.device_id)
      }
      if (this.linked == 'unlinked') {
        apartments = apartments.filter(a => !a.device_id)
      }
      if (this.$store.state.device.object_id) {
        apartments = apartments.filter(a => a.object_id === this.$store.state.device.object_id)
        return apartments
      }
      return [] // this.$store.state.apartments
    }
  },
  methods: {

    sortingChanged (ctx) {
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
      console.log('sortingChanged', ctx.sortBy, ctx.sortDesc)
      return false
    },
    pageClick () {
      setTimeout(() => {
        this.$store.dispatch('getManageDevices')
      }, 100)
    },

    changeGroup () {
      const device = {...this.$store.state.device}
      device.object_id = null
      device.apartment_id = null
      this.$store.commit('setData', {attr: 'device', value: device})
    },

    changeObject () {
      const device = {...this.$store.state.device}
      device.apartment_id = null
      this.$store.commit('setData', {attr: 'device', value: device})
      const apartments_filter = {...this.$store.state.apartments_filter}
      console.log('apartments_filter', apartments_filter);
      apartments_filter.page = null
      apartments_filter.object_id = this.$store.state.device.object_id
      this.$store.commit('setData', {attr: 'apartments_filter', value: apartments_filter})
      this.$store.dispatch('getManageApartments')
    },

    linkDevice () {
      if (!this.$store.state.device.group_id) {
        return this.$toasted.error('Группа не выбрана')
      }
      if (!this.$store.state.device.object_id) {
        return this.$toasted.error('Объект не выбран')
      }
      if (!this.$store.state.device.apartment_id) {
        return this.$toasted.error('Помещение не выбрано')
      }
      this.$store.dispatch('linkDevice')
    },

    linkNextDevice () {
      if (!this.$store.state.device.group_id) {
        return this.$toasted.error('Группа не выбрана')
      }
      if (!this.$store.state.device.object_id) {
        return this.$toasted.error('Объект не выбран')
      }
      if (!this.$store.state.device.apartment_id) {
        return this.$toasted.error('Помещение не выбрано')
      }
      this.$store.dispatch('linkNextDevice')
    },

  }
}
</script>

<style lang="scss">
.w-200-px{
  width: 200px;
}
ul.vs__dropdown-menu > li.vs__dropdown-option{
  max-width: 100%;
}
</style>
