<template>
  <div class="p-3 px-lg-4">
    <!-- <h6 class="mb-4">Установленные устройства</h6> -->
    <div class="d-flex flex-wrap">
      <div
        v-for="device in $store.state.devices"
        :key="device.id"
        class="device-card mx-2">
        <div class="card shadow-sm mb-4">
          <div class="card-header p-2 position-relative">
            <div class="position-absolute top-0 right-0">
              <button class="btn btn-sm py-1" @click="copy($event, device)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg>
              </button>
              <br>
              <button class="btn btn-sm py-1" @click="$toasted.error(t.notifDeveloped)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
              </button>
            </div>
            <router-link :to="{name: 'Device', params: {id: device.id}}" class="text-reset hover-no-decoration">
              {{ ['heat_flow_pipe', 'heat_return_pipe'].includes(device.device_type) ?
                (lang === 'ru' ? 'Теплосчетчик' : 'Теплолічильник')
                :
                (lang === 'ru' ? 'Неизвестное устройство' : 'Невідомий пристрій')
              }}
              <div class="text-warning">
                {{ device.man }}, № {{ device.serial_number }}
              </div>
              <div class="flh-100">
                <small>{{ lang === 'ru' ? 'Смонтированный по адресу' : 'Змонтований за адресою'}}:</small>
                <br>
                <div class="">
                  <small>{{
                    lang === 'ru' ?
                    `${device.apartment.object.name } кв. ${device.apartment.apartment_number}`
                    :
                    `${device.apartment.object.name_ua } кв. ${device.apartment.apartment_number_ua}`
                  }}</small>
                </div>
              </div>
            </router-link>
          </div>
          <div class="card-body p-2">
            <div class="row">
              <div class="col-7">{{ lang === 'ru' ? 'Суммарно' : 'Сумарно' }} Q:</div>
              <div class="col-5">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(device.data_last.e) }} Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">{{ lang === 'ru' ? 'С начала месяца' : 'З початку місяця' }} Q:</div>
              <div class="col-5">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(device.data_last.e - device.data_first.e) }} Гкал</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">{{ lang === 'ru' ? 'Суммарно' : 'Сумарно' }} V:</div>
              <div class="col-5">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(device.data_last.v) }} м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">{{ lang === 'ru' ? 'С начала месяца' : 'З початку місяця' }} V:</div>
              <div class="col-5">{{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(device.data_last.v - device.data_first.v) }} м³</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">{{ lang === 'ru' ? 'К оплате с начала месяца' : 'До оплати з початку місяця' }}:</div>
              <div class="col-5">
                {{ new Intl.NumberFormat('ua-UA', { style: 'currency', currency: 'UAH' }).format(device.apartment.object.heat_tariff * (device.data_last.e - device.data_first.e)) }}
              </div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">Статус:</div>
              <div class="col-5">{{ device.data_last.status_field === 'NoError' ? (lang === 'ru' ? 'Исправен' : 'Справний') : (lang === 'ru' ? 'Неисправен' : 'Несправний') }}</div>
            </div>
            <hr class="my-1">
            <div class="row">
              <div class="col-7">Обновлено:</div>
              <div class="col-5">{{ device.data_last.date_time_of_request | moment('from') }}</div>
            </div>
            <hr class="my-1">
            <small class="font-weight-bold">
              {{ lang === 'ru' ? '"К оплате с начала месяца" является ориентировочной суммой согласно установленного тарифа' : '"До cплати з початку місяця" є орієнтовною сумою згідно з встановленим тарифом' }}
              {{ device.apartment.object.heat_tariff }}
              {{ lang === 'ru' ? 'грн. за 1 Гкал без учета оплаты за МЗК' : 'грн. 1 Гкал без урахування оплати за МЗК' }}.
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Devices',

  computed: {
    lang () {
      return this.$store.state.lang
    },
    t () {
      return this.$store.state.i18n[this.lang]
    }
  },

  methods: {
    copy (event, device) {
      event.preventDefault()
      // const text = event.target.parentElement.parentElement.parentElement.parentElement.textContent
      let text = ''
      text += ['heat_flow_pipe', 'heat_return_pipe'].includes(device.device_type) ? 'Теплосчетчик' : 'Неизвестное устройство'
      text += ` ${device.man}, № ${device.serial_number}\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'Смонтированный по адресу' : 'Змонтований за адресою'}:\n`
      if (this.lang === 'ru') {
        text += `${device.apartment.object.name} кв. ${device.apartment.apartment_number}\n`
      } else {
        text += `${device.apartment.object.name_ua} кв. ${device.apartment.apartment_number_ua}\n`
      }
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'Суммарно' : 'Сумарно'} Q: ${new Intl.NumberFormat('ua-UA').format(device.data_last.e)} Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'С начала месяца' : 'З початку місяця'} Q: ${new Intl.NumberFormat('ua-UA').format(device.data_last.e - device.data_first.e)} Гкал\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'Суммарно' : 'Сумарно'} V: ${ new Intl.NumberFormat('ua-UA').format(device.data_last.v) } м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'С начала месяца' : 'З початку місяця'} V: ${new Intl.NumberFormat('ua-UA').format(device.data_last.v - device.data_first.v) } м³\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `${this.lang === 'ru' ? 'К оплате с начала месяца' : 'До оплати з початку місяця'}: ${new Intl.NumberFormat('ua-UA', { style: 'currency', currency: 'UAH' }).format(device.apartment.object.heat_tariff * (device.data_last.e - device.data_first.e))}\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `Статус: ${ device.data_last.status_field === 'NoError' ? (this.lang === 'ru' ? 'Исправен' : 'Справний') : (this.lang === 'ru' ? 'Неисправен' : 'Несправний')}\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      text += `Обновлено: ${this.$moment(device.data_last.date_time_of_request).fromNow()}\n`
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      if (this.lang === 'ru') {
        text += `"К оплате с начала месяца" является ориентировочной \nсуммой согласно установленного тарифа \n${device.apartment.object.heat_tariff} грн. за 1 Гкал без учета оплаты за МЗК.\n`
      } else {
        text += `"До cплати з початку місяця" є орієнтовною сумою \nзгідно з встановленим тарифом \n${device.apartment.object.heat_tariff} грн. 1 Гкал без урахування оплати за МЗК.\n`
      }
      text += '- - - - - - - - - - - - - - - - - - - - - - - -\n'
      this.$toasted.info(this.lang === 'ru' ? 'Скопировано' : 'Скопійовано')
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  },

  async mounted () {
    try {
      this.$store.dispatch('getUserDevices')
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`, {duration: 1000})
    }
  }
}
</script>

<style media="screen" lang="scss">

</style>
