import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import {store} from './store'
import App from './App.vue'
import VueGoodTablePlugin from 'vue-good-table'
Vue.use(VueGoodTablePlugin)

import vSelect from "vue-select"
Vue.component("v-select", vSelect)

import VueResource from 'vue-resource'
Vue.use(VueResource)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Index from './pages/Index'
import Objects from './pages/Objects'
import Object from './pages/Object'
import Devices from './pages/Devices'
import ManagerDevice from './pages/ManagerDevice'
import Notifications from './pages/Notifications'
import Prediction from './pages/Prediction'
import Reports from './pages/Reports'
import Account from './pages/Account'
import ManageGroups from './pages/manage/Groups'
import ManageObjects from './pages/manage/Objects'
import ManageApartments from './pages/manage/Apartments'
import ManageDevices from './pages/manage/Devices'
import ManageAccounts from './pages/manage/Accounts'
import ManageParser from './pages/manage/Parser'
import HelpUser from './pages/help/User'
import HelpManager from './pages/help/Manager'
const routes = [
  { path: '/', component: Index },
  { path: '/objects', component: Objects },
  { path: '/objects/:id', component: Object, name: 'Object' },
  { path: '/manager/devices/:id', component: ManagerDevice, name: 'ManagerDevice' },
  { path: '/devices', component: Devices },
  { path: '/devices/:id', component: ManagerDevice, name: 'Device' },
  { path: '/notifications', component: Notifications, name: 'Notifications' },
  { path: '/prediction', component: Prediction, name: 'Prediction' },
  { path: '/reports/:id', component: Reports, name: 'Reports' },
  { path: '/account', component: Account, name: 'Account' },
  { path: '/manage/groups', component: ManageGroups, name: 'ManageGroups' },
  { path: '/manage/objects', component: ManageObjects, name: 'ManageObjects' },
  { path: '/manage/apartments', component: ManageApartments, name: 'ManageApartments' },
  { path: '/manage/devices', component: ManageDevices, name: 'ManageDevices' },
  { path: '/manage/accounts', component: ManageAccounts, name: 'ManageAccounts' },
  { path: '/manage/parser', component: ManageParser, name: 'ManageParser' },
  { path: '/help_user', component: HelpUser, name: 'HelpUser' },
  { path: '/help_manager', component: HelpManager, name: 'HelpManager' },
]
export const router = new VueRouter({
  mode: 'history',
  routes
})

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'viewerjs/dist/viewer.css'

import Viewer from 'v-viewer'
Vue.use(Viewer)

const moment = require('moment')
require('moment/locale/ru')

Vue.use(require('vue-moment'), {
  moment
})

import Toasted from 'vue-toasted'
Vue.use(Toasted, {duration: 2000})

Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
