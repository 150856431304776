<template lang="html">
  <div class="pt-3">
    <div class="d-flex">
      <h3>Помещения</h3>

      <div class="mx-auto d-flex">
        <div class="w-200-px mr-3">
          <v-select
            placeholder="Группа"
            v-model="$store.state.apartments_filter.group_id"
            :options="$store.state.groups"
            :reduce="group => group.id"
            :getOptionLabel="group => group.name"
            @input="$store.dispatch('getManageApartments')">
          </v-select>
        </div>
        <div class="w-200-px mr-3">
          <v-select
            placeholder="Объект"
            v-model="$store.state.apartments_filter.object_id"
            :options="filterObjects"
            :reduce="object => object.id"
            :getOptionLabel="object => `${object.group_name}, ${object.name}`"
            @input="$store.dispatch('getManageApartments')">
          </v-select>
        </div>
        <div class="w-200-px mr-3">
          <v-select
            placeholder="Тип"
            v-model="$store.state.apartments_filter.apartment_type"
            :options="['residential', 'commercial']"
            @input="$store.dispatch('getManageApartments')">
          </v-select>
        </div>

        <!-- <b-form-input
          v-model="$store.state.apartments_filter.apartment_number_from"
          placeholder="Enter your name">
        </b-form-input> -->

      </div>

      <div class="">
        <button class="btn btn-light"
          @click="$store.commit('setData', {attr: 'apartment', value: {}}); $bvModal.show('itemModal')">
          Добавить
        </button>
      </div>
    </div>
    <b-table
      head-variant="light"
      small
      responsive
      bordered
      :fields="fields"
      :items="$store.state.apartments">
      <template #cell(btn)="data">
        <b-button
          variant="light"
          @click="$store.commit('setData', {attr: 'apartment', value: data.item}); $bvModal.show('itemModal')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </b-button>
        <button
          class="btn btn-light"
          @click="$store.commit('setData', {attr: 'apartment', value: data.item}); $store.dispatch('deleteApartment')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </template>
    </b-table>

    <b-pagination
      v-model="$store.state.apartments_filter.page"
      :total-rows="$store.state.total_apartments"
      :per-page="50"
      @page-click="pageClick">
    </b-pagination>

    <b-modal id="itemModal" hide-footer title="Помещение">
      <form ref="form" @submit.stop.prevent="$store.dispatch('saveApartment')">

        <b-form-group
          label="Аккаунт"
          label-for="user_id-select">
          <b-form-select
            v-model="$store.state.apartment.user_id"
            :options="$store.state.customers.map(c => {return {value: c.user.id, text: `${c.user.first_name} ${c.user.last_name}`}})">
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Объект"
          label-for="object-select"
          invalid-feedback="Объект - обязательное поле">
          <b-form-select
            required
            v-model="$store.state.apartment.object_id"
            :options="$store.state.objects.map(o => {return {value: o.id, text: `${o.group_name} ${o.name}`}})">
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Номер помещения"
          label-for="apartment_number-input"
          invalid-feedback="Номер обязательно">
          <b-form-input
            id="apartment_number-input"
            v-model="$store.state.apartment.apartment_number"
            required>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Номер помещения, укр"
          label-for="apartment_number_ua-input"
          invalid-feedback="Номер обязательно">
          <b-form-input
            id="apartment_number_ua-input"
            v-model="$store.state.apartment.apartment_number_ua"
            required>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Тип"
          label-for="apartment_type-select"
          invalid-feedback="Тип - обязательное поле">
          <b-form-select
            required
            v-model="$store.state.apartment.apartment_type"
            :options="['residential', 'commercial']">
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Жилая площадь"
          label-for="common_square-input">
          <b-form-input
            id="common_square-input"
            number
            v-model="$store.state.apartment.common_square">
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Отапливаемая площадь"
          label-for="heating_square-input">
          <b-form-input
            id="heating_square-input"
            number
            v-model="$store.state.apartment.heating_square">
          </b-form-input>
        </b-form-group>

        <button type="submit" class="btn btn-primary">Сохранить</button>
      </form>
    </b-modal>

  </div>
</template>

<script>
export default {
  name: 'ManageObjects',
  data: () => {
    return {
      fields: [
        {label: 'Группа', key: 'group_name'},
        {label: 'Группа, укр', key: 'group_name_ua'},
        {label: 'Объект', key: 'object_name'},
        {label: 'Объект, укр', key: 'object_name_ua'},
        {label: 'Номер', key: 'apartment_number'},
        {label: 'Номер, укр', key: 'apartment_number_ua'},
        {label: 'Тип', key: 'apartment_type'},
        {label: 'Площадь', key: 'common_square'},
        {label: 'Площадь отапливаемая', key: 'heating_square'},
        {label: '', key: 'btn', th_class: 'text-right'},
      ]
    }
  },
  computed: {
    filterObjects () {
      if (this.$store.state.apartments_filter.group_id) {
        return this.$store.state.objects.filter(o => o.group_id === this.$store.state.apartments_filter.group_id)
      }
      return this.$store.state.objects
    }
  },
  methods: {
    pageClick () {
      setTimeout(() => {
        this.$store.dispatch('getManageApartments')
      }, 100)
    },
  },
  mounted () {
    const apartments_filter = {
      page: 1,
      apartment_type: null,
      group_id: null,
      object_id: null,
    }
    this.$store.commit('setData', {attr: 'apartments_filter', value: apartments_filter})
    this.$store.dispatch('getManageApartments')
  }
}
</script>

<style lang="css" scoped>

</style>
