<template>
  <div class="p-3 p-lg-4">
    <div v-if="$store.state.lang === 'ru'" class="">
      <h3>Панель устройств</h3>
      <p>Панель устройства – предназначена для быстрого просмотра информации с устройств, копирования данной информации в буфер обмена, инициации мгновенно опроса. </p>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <img src="/img/userwidget-ru.png" class="mw-100">
        </div>
      </div>

      <p>[1] – Тип устройства(вода, тепло, электричество, газ).</p>
      <p>[2] – Код производителя, серийный номер – устройства.</p>
      <p>[3] – Адрес где смонтировано устройство.</p>
      <p>[4]  - Скопировать данные панели устройства в текстовом формате в буфер обмена.</p>
      <p class="text-black-50">[5]- Мгновенный опрос устройства – служит для получения текущих данных с устройства, не дожидаясь опроса, инициированного сервером с последующим занесением в базу данных свежей информации. <br>Данная функция может использована не более 12 раз в сутки для предотвращения перегрузки канала связи, также будет временно не активна на момент общего опроса устройств сервером или администратором (данная функция на этапе разработки).</p>
      <p>[6] - Накопленная тепловая энергия на теплосчетчике за весь период работы.</p>
      <p>[7]  - Накопленная тепловая энергия с начала текущего месяца,  обнуляется с 1-го числа каждого месяца.</p>
      <p>[8] - Накопленный объем теплоносителя на теплосчетчике за весь период работы.</p>
      <p>[9] - Накопленный объем теплоносителя с начала текущего месяца, обнуляется с 1-го числа каждого месяца.</p>
      <p>[10]  - «К оплате с начала месяца» - ориентировочная сумма оплаты за потребленную тепловую энергию с начала текущего месяца в грн., которая вычисляется как произведение –
      <br>«С начала месяца Q» * «Установленный тариф в грн. за 1 Гкал» = «К оплате с начала месяца». <br>Данное значения служит для ориентировочного понимания суммы оплаты за потреблённую тепловую энергию с начала месяца без учета оплаты за МОП (места общего пользования) и может отличаться от значений в квитанции если был выполнен перерасчет за прошлый месяц(ы).</p>

      <p>[11] - «Статус устройства» - Отображение состояния/комбинаций состояний устройства с момента последнего опроса.<br>
      Возможные статусы и причины их возникновения:<br>
      «Исправен» - устройство работает в штатном режиме;<br>
      «Розряд элемента питания» - зафиксирован розряд элемента питания, необходимо обратиться в обслуживающую компанию для замены элемента питания;<br>
      «Нет расхода»  - нет протока теплоносителя, счетчик перекрыт или застопорилась крыльчатка из-за попадания твердых частиц если расходомер механический;<br>
       «Нет связи с устройством» - обрыв проводной линии связи или нарушение работы ретранслятора для радиоканала, устройство демонтировано;<br>
      «Обрыв термопары» - обрыв одного или двух датчиков температуры, счетчик не исправен -необходимо обратиться обслуживающую компанию;<br>
      «Обрыва датчика расхода» - обрыв датчика расхода, счетчик не исправен - необходимо обратиться в обслуживающую компанию;<br>
      «Критическая ошибка аппаратного обеспечения устройства» - счетчик не исправен, необходимо обратиться обслуживающую компанию;<br>
      «Критическая ошибка программного обеспечения устройства» - счетчик не исправен, необходимо обратиться обслуживающую компанию;<br>
      «Кавитация в трубопроводе» - образования пузырьков воздуха в системе отопления(фиксируется только ультразвуковым расходомером);<p>
      <p>[12] - Пройденное время с момента последнего опроса устройства.</p>


      <h3>Статистика за период</h3>
      <p>Данная вкладка служит для детализированного просмотра данных с устройства с представлением в виде таблиц и графиков по временным точкам опроса за выбранный период времени, формированием отчетов в форматах PDF и Excel.</p>

      <img src="/img/userstat.png" class="mw-100">

      <p>[13] – Тип устройства(вода, тепло, электричество, газ).</p>
      <p>[14] – Код производителя устройства.</p>
      <p>[15] – Серийный номер устройства.</p>
      <p>[16] – Адрес где смонтировано устройство.</p>
      <p>[17] – Номер помещения к которому относиться устройство.</p>
      <p>[18] – Тип помещения к которому относиться устройство, жилое – квартира, не жилое коммерческое помещение.</p>
      <p>[19] – Выбор периода времени для отображения данных и формирования отчетов устройства.</p>
      <p>[20] – Формирование отчетов в форматах PDF, Excel за выбранный период.</p>
      <p>[21] – Порядковый номер точки опроса.</p>
      <p>[22] – Дата и время получения данных с устройства.</p>
      <p>[23] – Тепловая энергия в единицах устройства.</p>
      <p>[24] – Единицы накопления устройства.</p>
      <p>[25] – Тепловая энергия, конвертированная в Гкал.</p>
      <p>[26] – Значение разности накопленной тепловой энергии между двумя последними точками опроса.</p>
      <p>[27]– Значение разности тепловой энергии между двумя последними точками опроса умноженное на установленный тариф в грн.</p>
      <p>[28] – Объем во внутренних единицах устройства единицах устройства.</p>
      <p>[29] – Внутренние единицы объема устройства.</p>
      <p>[30] - Значение разности объема между двумя последними точками опроса.</p>
      <p>[31] – Температура теплоносителя в подающем трубопроводе.</p>
      <p>[32] - Температура теплоносителя в обратном трубопроводе.</p>
      <p>[33] – Разность температур теплоносителя в подающем и обратном трубопроводах.</p>
      <p>[34] – Среднесуточная температура воздуха для региона где смонтировано устройство.</p>
      <p>[34] – Статус устройства.</p>
      <p>[35] – Код ошибки/состояния устройства.</p>
    </div>
    <div v-else-if="$store.state.lang === 'ua'" class="">
      <h3>Панель пристрою</h3>
      <p>Панель пристрою – призначена для швидкого перегляду інформації з пристрою, копіювання даної інформації у буфер обміну, ініціації миттєвого опитування.</p>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <img src="/img/userwidget-ua.png" class="mw-100">
        </div>
      </div>

      <p>[1] – Тип пристрою (вода, тепло, електрика, газ).</p>
      <p>[2] – Код виробника, серійний номер  пристрою.</p>
      <p>[3] – Адреса, де змонтовано пристрій.</p>
      <p>[4] - Копіювати дані панелі пристрою в текстовому форматі до буфера обміну.</p>
      <p class="text-black-50">[5] - Миттєве опитування пристрою – служить отримання поточних даних із пристрою, не чекаючи опитування, ініційованого сервером з наступним занесенням до бази даних оновленої інформації. <br>Ця функція може бути використана не більше 12 разів на добу для запобігання перевантаженню каналу зв'язку, також буде тимчасово не активна на момент загального опитування пристроїв сервером або адміністратором (дана функція в процесі розробки).</p>
      <p>[6] - Накопичена теплова енергія на теплолічильнику за  весь період роботи.</p>
      <p>[7] - Накопичена теплова енергія з початку поточного місяця, обнулюється з 1 числа кожного місяця.</p>
      <p>[8] - Накопичений об’єм теплоносія на теплолічильнику за весь період роботи.</p>
      <p>[9] - Накопичений обсяг теплоносія з початку поточного місяця, обнулюється з 1 числа кожного місяця.</p>
      <p>[10] - «До оплати з початку місяця» - орієнтовна сума сплати за спожиту теплову енергію з початку поточного місяця в грн., яка обчислюється як множення –
      <br>«З початку місяця Q» * «Встановлений тариф у грн. за 1 Гкал» = «До оплати з початку місяця». <br>
      Це значення служить для орієнтовного розуміння суми оплати за спожиту теплову енергію з початку місяця без урахування оплати за МОП (місця загального користування) і може відрізнятися від значень у квитанції, якщо було виконано перерахунок за минулий місяць(і).</p>

      <p>[11] - «Статус пристрою» - Відображення стану/комбінацій станів пристрою з моменту останнього опитування.<br>
      Можливі статуси та причини їх виникнення:<br>
      «Справний» - пристрій працює в штатному режимі;<br>
      «Розряд елемента живлення» - зафіксований розряд елемента живлення, необхідно звернутися до обслуговуючої компанії для заміни елемента живлення;<br>
      «Немає витрати» - відсутній протоктеплоносія, лічильник перекритий або застопорилася крильчатка через попадання твердих частинок якщо витратомір механічний;<br>
      «Немає зв'язку з пристроєм» - обрив провідної лінії зв'язку або порушення роботи ретранслятора для радіоканалу, пристрій демонтований;<br>
      «Обрив термопари» - обрив одного або двох датчиків температури, лічильник несправний - необхідно звернутися до обслуговуючої компанії;<br>
      «Обрив датчика витрати» - обрив датчика витрати, лічильник не справний - необхідно звернутися до обслуговуючої компанії;<br>
      «Критична помилка апаратного забезпечення пристрою» - лічильник несправний, необхідно звернутися до обслуговуючої компанії;<br>
      «Критична помилка програмного забезпечення пристрою» - лічильник несправний, необхідно звернутися до обслуговуючої компанії;<br>
      "Кавітація в трубопроводі" - утворення бульбашок повітря в системі опалення (фіксується тільки ультразвуковим витратоміром);<p>
      <p>[12] - Пройдений час з останнього опитування пристрою.</p>


      <h3>Статистика за період</h3>
      <p>Ця вкладка служить для детального перегляду даних із пристрою з поданням у вигляді таблиць та графіків за часовими точками опитування за вибраний період часу, формуванням звітів у форматах PDF та Excel.</p>

      <img src="/img/userstat.png" class="mw-100">

      <p>[13] – Тип пристрою (вода, тепло, електрика, газ).</p>
      <p>[14] – Код виробника пристрою.</p>
      <p>[15] – Серійний номер пристрою.</p>
      <p>[16] – Адреса де змонтовано пристрій.</p>
      <p>[17] – Номер приміщення до якого належить пристрій.</p>
      <p>[18] – Тип приміщення якого ставитися пристрій, житлове – квартира, не житлове комерційне приміщення.</p>
      <p>[19] – Вибір періоду часу для відображення даних та формування звітів пристрою.</p>
      <p>[20] – Формування звітів у форматах PDF, Excel за вибраний період.</p>
      <p>[21] – Порядковий номер точки опитування.</p>
      <p>[22] – Дата та час отримання даних із пристрою.</p>
      <p>[23] – Теплова енергія в одиницях пристрою.</p>
      <p>[24] – Одиниці накопичення пристрою.</p>
      <p>[25] – Теплова енергія, конвертована в Гкал.</p>
      <p>[26] – Значення різниці накопиченої теплової енергії між двома останніми точками опитування.</p>
      <p>[27] – Значення різниці теплової енергії між двома останніми точками опитування помножене на встановлений тариф у грн.</p>
      <p>[28] – Об'єм у внутрішніх одиницях пристрою одиницях пристрою.</p>
      <p>[29] – Внутрішні одиниці об'єму пристрою.</p>
      <p>[30] - Значення різниці об'єму між двома останніми точками опитування.</p>
      <p>[31] – Температура теплоносія в подавальному  трубопроводі.</p>
      <p>[32] - Температура теплоносія у зворотньому трубопроводі.</p>
      <p>[33] – Різниця температур теплоносія в подавальному  і зворотньому трубопроводах.</p>
      <p>[34] – Середньодобова температура повітря для регіону, де змонтовано пристрій.</p>
      <p>[34] – Статус пристрою.</p>
      <p>[35] – Код помилки/стану пристрою.</p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
