<template lang="html">
  <div class="py-3">
    <div class="d-flex">
      <h3>Аккаунты</h3>

      <div class="mx-auto d-flex">
        <div class="">
          <b-form-datepicker
            v-model="$store.state.customers_filter.date_joined_from"
            @input="$store.dispatch('getManageCustomers')"
            placeholder="Дата регистрации от"
            class="mr-2">
          </b-form-datepicker>
        </div>
        <div class="">
          <b-form-datepicker
            v-model="$store.state.customers_filter.date_joined_to"
            @input="$store.dispatch('getManageCustomers')"
            placeholder="Дата регистрации до">
          </b-form-datepicker>
        </div>
      </div>

    </div>
    <b-table
      head-variant="light"
      small
      responsive
      bordered
      :fields="fields"
      :items="$store.state.customers">
      <template #cell(fullname)="data">
        {{ data.item.user.last_name }}
        {{ data.item.user.first_name }}
        {{ data.item.patronymic }}
      </template>
      <template #cell(activation)="data">
        <b-button
          :variant="data.item.user.is_active ? 'danger' : 'success'"
          size="sm"
          @click="$store.commit('setData', {attr: 'customer', value: data.item}); $store.dispatch('changeActivateUser')">
          {{ data.item.user.is_active ? 'Деактивировать' : 'Активировать' }}
        </b-button>
      </template>
      <template #cell(file)="data">
        <a v-if="data.item.file" :href="data.item.file" target="_blank">Файл</a>
      </template>
      <template #cell(apartment)="data">
        <span v-if="data.item.apartment">
          {{ data.item.apartment.group_name }}
          {{ data.item.apartment.object_name }}
          {{ data.item.apartment.apartment_number }}
        </span>
      </template>
      <template #cell(letter)="data">
        <b-button
          variant="info"
          size="sm"
          @click="$store.commit('setData', {attr: 'customer', value: data.item}); $store.dispatch('sendUserCreds')">
          Отправить
        </b-button>
      </template>
      <template #cell(btn)="data">
        <button
          class="btn btn-light btn-sm"
          title="Удалить"
          @click="$store.commit('setData', {attr: 'customer', value: data.item}); $store.dispatch('deleteCustomer')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </template>
    </b-table>

    <div class="d-flex align-items-center">
      <!-- <b-pagination
        v-model="$store.state.customers_filter.page"
        :total-rows="$store.state.total_customers"
        :per-page="50"
        class="mb-0"
        @page-click="pageClick">
      </b-pagination> -->

      <div class="ml-3">
        Всего аккаунтов {{ $store.state.total_customers }}
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')
export default {
  name: 'ManageObjects',
  data: () => {
    return {
      linked: null,
      fields: [
        {label: 'Дата регистрации', key: 'user.date_joined'},
        {label: 'Последний вход', key: 'user.last_login'},
        {label: 'Адрес', key: 'apartment'},
        {label: 'Логин', key: 'user.username'},
        {label: 'Email', key: 'user.email'},
        {label: 'Телефон', key: 'phone'},
        {label: 'ФИО', key: 'fullname'},
        {label: 'Статус', key: 'user.is_active'},
        {label: 'Активация', key: 'activation'},
        {label: 'Фото', key: 'file'},
        {label: 'Логин/пароль', key: 'letter'},
        // {label: 'Производитель', key: 'man'},
        // {label: 'Тип', key: 'device_type'},
        // {label: 'Версия ПО', key: 'firmware_ver'},
        // {label: 'Статус', key: 'device_status'},
        // {label: 'Mcl', key: 'mcl_id'},
        // {label: 'Дата последнего опроса', key: 'date_time_of_last_answer'},
        // {label: 'Привязан', key: 'linked'},
        {label: '', key: 'btn', th_class: 'text-right'},
      ]
    }
  },
  computed: {
    filterObjects () {
      if (this.$store.state.device.group_id) {
        return this.$store.state.objects.filter(o => o.group_id === this.$store.state.device.group_id)
      }
      return [] // this.$store.state.objects
    },
    filterApartments () {
      let apartments = this.$store.state.apartments
      if (this.$store.state.device.apartment_type) {
        apartments = apartments.filter(a => a.apartment_type === this.$store.state.device.apartment_type)
      }
      if (this.linked == 'linked') {
        apartments = apartments.filter(a => a.device_id)
      }
      if (this.linked == 'unlinked') {
        apartments = apartments.filter(a => !a.device_id)
      }
      if (this.$store.state.device.object_id) {
        apartments = apartments.filter(a => a.object_id === this.$store.state.device.object_id)
        return apartments
      }
      return [] // this.$store.state.apartments
    }
  },
  methods: {
    pageClick () {
      setTimeout(() => {
        this.$store.dispatch('getManageCustomers')
      }, 100)
    },
  }
}
</script>

<style lang="css" scoped>
.w-200-px{
  width: 200px;
}
</style>
