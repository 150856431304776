<template lang="html">
  <div class="p-3 p-lg-4" v-if="$store.state.object">

    <div class="fw-700 fs-14 d-flex flex-wrap align-items-center">

      <div class="mr-3 mb-3">
        {{ {ru: 'Выбор объекта', ua: "Вибір об'єкту"}[lang] }}:
      </div>

      <div class="mr-4 mb-3 mw-100 dropdown-objects">
        <b-dropdown
          variant="transparent"
          toggle-class="border-dark fw-700 fs-14 overflow-x-hidden"
          no-caret
          :block="true"
          v-if="$store.state.object.group_name"
          :text="lang === 'ru' ? ($store.state.object.group_name + ': ' + $store.state.object.name) : ($store.state.object.group_name_ua + ': ' + $store.state.object.name_ua)">
          <b-dropdown-item
            v-for="object in $store.state.objects"
            :key="object.id"
            :to="'/objects/' + object.id"
            class="border-bottom">
            <strong>{{ lang === 'ru' ? object.group_name : object.group_name_ua }}</strong><br> {{ lang === 'ru' ? object.name : object.name_ua }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="d-flex align-items-center position-relative">
        <div class="mr-3 mb-3">
          {{ {ru: 'Начало периода', ua: 'Початок періоду'}[lang] }}:
        </div>
        <Datepicker
          v-model="$store.state.date_from"
          :format="customFormatter"
          @input="setDateFrom"
          :disabled-dates="{
            from: $moment().toDate()
          }"
          :language="lang === 'ru' ? ru : ua"
          :monday-first="true"
          input-class="form-control bg-transparent border-dark fw-700 fs-14 text-dark mr-4 mb-3"/>
      </div>

      <div class="d-flex align-items-center position-relative">
        <div class="mr-3 mb-3">
          {{ {ru: 'Конец периода', ua: 'Кінець періоду'}[lang] }}:
        </div>

        <Datepicker
          v-model="$store.state.date_to"
          :format="customFormatter"
          :disabled-dates="{
            to: $store.state.date_from,
            from: $moment($store.state.date_from).add(6, 'month').toDate()
          }"
          :language="lang === 'ru' ? ru : ua"
          :monday-first="true"
          input-class="form-control bg-transparent border-dark fw-700 fs-14 text-dark mr-4 mb-3"/>
      </div>

      <div class="mr-3 mb-3">
        {{ {ru: 'Экспортировать в', ua: 'Експортувати в'}[lang] }}:
      </div>

      <a
        :href="'/api/report/object/' + $store.state.object.id + '?format=xlsx&lang=' + lang + '&from=' + $moment($store.state.date_from).unix() + '&to=' + $moment($store.state.date_to).unix()"
        target="_blank"
        class="mb-3">
        <img src="/img/excel.png" height="26">
      </a>
      <a
        :href="'/api/report/object/' + $store.state.object.id + '?format=pdf&lang=' + lang + '&from=' + $moment($store.state.date_from).unix() + '&to=' + $moment($store.state.date_to).unix()"
        target="_blank"
        class="ml-3 text-danger mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
          <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
          <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
        </svg>
      </a>

    </div>

    <!-- :sticky-header="table_height" -->

    <b-tabs
      :small="$store.state.vw < 992"
      pills
      content-class="mt-3">
      <b-tab :title="lang === 'ru' ? 'Таблица' : 'Таблиця'" active>
        <b-table
          id="object-table"
          head-variant="light"
          foot-varian="light"
          responsive
          bordered
          small
          :fields="fields"
          :items="$store.state.object.apartments">
          <template #cell(serial_number)="data">
            <router-link v-if="data.item.device" :to="'/manager/devices/' + data.item.device.id" class="btn btn-primary btn-sm">
              {{ data.item.device.serial_number }}
            </router-link>
          </template>
          <template #cell(date_time_of_request)="data">
            <span v-if="data.item.device && data.item.device.data.length">
              {{ data.item.device.data[0].date_time_of_request | moment('DD.MM.YYYY HH:mm') }}
            </span>
          </template>
          <template #cell(apartment_type)="data">
            {{ {residential: (lang === 'ru' ? 'Жилое' : 'Житлове'), commercial: (lang === 'ru' ? 'Коммерческое' : 'Коммерційне')}[data.item.apartment_type] }}
          </template>
          <template #cell(de)="data">
            <span v-if="data.item.de != undefined">
              {{ new Intl.NumberFormat('ua-UA', {minimumFractionDigits: 3}).format(data.item.de) }}
            </span>
          </template>
          <template #cell(dv)="data">
            <span v-if="data.item.dv != undefined">
              {{ new Intl.NumberFormat().format(data.item.dv) }}
            </span>
          </template>
          <template #cell(mt)>
            15
          </template>
          <template #cell(errorFlag)>
            {{ lang === 'ru' ? 'Отсутствуют' : 'Відсутні' }}
          </template>

          <template #custom-foot>
            <b-tr>
              <b-th colspan="7" class="text-right">{{ lang === 'ru' ? 'Сумма' : 'Сума' }}</b-th>
              <b-th class="text-center">
                <span v-if="$store.state.object.stat">
                  {{ new Intl.NumberFormat().format($store.state.object.stat.e) }}
                </span>
              </b-th>
              <b-th colspan="2" class="text-right">{{ lang === 'ru' ? 'Сумма' : 'Сума' }}</b-th>
              <b-th class="text-center">
                <span v-if="$store.state.object.stat">
                  {{ new Intl.NumberFormat().format($store.state.object.stat.v) }}
                </span>
              </b-th>
              <b-th colspan="3"></b-th>
            </b-tr>
          </template>
        </b-table>
      </b-tab>
      <b-tab :title="$store.state.vw < 992 ? 'Q, Гкал' : (lang === 'ru' ? 'График потребления Q, Гкал' : 'Графік споживання Q, Гкал')">
        <div class="h-300-px">
          <apexchart
            v-if="$store.state.object && $store.state.object.stat"
            type="line"
            :options="getQGraphOptions"
            :key="0"
            :height="300"
            :series="getGraphData">
          </apexchart>
        </div>
        <div class="h-300-px mb-5">
          <apexchart
            type="line"
            :options="getWeatherGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataWeather">
          </apexchart>
        </div>
      </b-tab>
      <b-tab :title="$store.state.vw < 992 ? 'V, м³' : (lang === 'ru' ? 'График расхода теплоносителя V, м³' : 'Графік витрати теплоносія V, м³')">
        <div class="h-300-px">
          <apexchart
            v-if="$store.state.object && $store.state.object.stat"
            type="line"
            :options="getVGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataV">
          </apexchart>
        </div>
        <div class="h-300-px mb-5">
          <apexchart
            type="line"
            :options="getWeatherGraphOptions"
            :key="1"
            :height="300"
            :series="getGraphDataWeather">
          </apexchart>
        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
const vdp_translation = require('vuejs-datepicker/dist/locale/translations/ru.js')
import vdp_translation_ua from  'vuejs-datepicker/dist/locale/translations/uk'

export default {
  name: 'Object',

  components: {
    Datepicker
  },

  data: function () {
    return {
      ru: vdp_translation,
      ua: vdp_translation_ua,
      table_height: '',
    }
  },

  watch:{
    $route (to) {
      this.$store.commit('setData', {
        attr: 'object',
        value: {
          id: to.params.id
        }
      })
      this.$store.dispatch('getObjectData')
    },
    '$store.state.date_from' () {
      this.$store.dispatch('getObjectData')
    },
    '$store.state.date_to' () {
      this.$store.dispatch('getObjectData')
    }
  },

  computed: {

    fields () {
      return [
        {
          label: this.lang === 'ru' ? 'Дата и время опроса устройства' : 'Дата та час опитування пристрою',
          key: 'date_time_of_request',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? '№ Помещения' : '№ приміщення',
          key: this.lang === 'ru' ? 'apartment_number' : 'apartment_number_ua',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Тип помещения' : 'Тип приміщення',
          key: 'apartment_type',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Код производителя устройства' : 'Код виробника пристрою',
          key: 'device.man',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Серийный номер устройства' : 'Серійний номер пристрою',
          key: 'serial_number',
        },
        {
          label: this.lang === 'ru' ? 'Q (В единицах устройства)' : 'Q (В одиницях пристрою)',
          key: 'device.data[0].e_origin',
          // variant: 'warning',
          tdClass: 'bg-heat',
          thClass: 'th-w-85',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Q (единицы)' : 'Q (одиниці)',
          key: 'device.data[0].eu',
          tdClass: 'bg-heat',
          thClass: 'th-w-85',
          sortable: true
        },
        {
          label: 'ΔQ (Гкал)',
          key: 'de',
          tdClass: 'bg-deep-heat',
          thClass: 'th-w-85',
          sortable: true
        },
        // {
        //   label: 'ΔQ (Гкал)',
        //   key: 'egcal',
        //   tdClass: 'bg-deep-heat',
        //   thClass: 'th-w-85',
        //   sortable: true
        // },
        {
          label: this.lang === 'ru' ? 'V (В единицах устройства)' : 'V (В одиницях пристрою)',
          key: 'device.data[0].v',
          tdClass: 'bg-water',
          thClass: 'th-w-85',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'V (единицы)' : 'V (одиниці)',
          key: 'device.data[0].vu',
          tdClass: 'bg-water',
          thClass: 'th-w-85',
          sortable: true
        },
        {
          label: 'ΔV (м³)',
          key: 'dv',
          tdClass: 'bg-deep-water',
          thClass: 'th-w-85',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Статус устройства' : 'Статус пристрою',
          key: 'device.data[0].status_field',
          sortable: true
        },
        {
          label: this.lang === 'ru' ? 'Ошибки на устройстве' : 'Помилки на пристрої',
          key: 'errorFlag',
          sortable: true
        },
        {
          label: 'Код прошивки',
          key: 'device.firmware_ver',
          sortable: true
        }
      ]
    },

    lang () {
      return this.$store.state.lang
    },

    getGraphDataWeather () {
      if (this.$store.state.object.stat) {
        return [
          {
            name: this.lang === 'ru' ? 'Среднесуточная температура воздуха' : 'Середньодобова температура повітря',
            // data: .reverse()
            data: Object.keys(this.$store.state.object.stat.graph_e).map(d => this.getTemp(d)).reverse()
          }
        ]
      } else {
        return []
      }
    },

    getWeatherGraphOptions () {
      if (this.$store.state.object.stat) {
        return {
          chart: {
            id: 'vuechart-w',
            height: '100%',
          },
          colors: ['#21cc46'],
          stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: Object.keys(this.$store.state.object.stat.graph_e).reverse()//this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
          }
        }
      } else {
        return {}
      }
    },

    getGraphData () {
      if (this.$store.state.object.stat) {
        return [{
          name: `Q`,
          data: Object.values(this.$store.state.object.stat.graph_e).map(v => parseFloat(v.toFixed(3))).reverse()
        }]
      } else {
        return []
      }
    },

    getGraphDataV () {
      return [{
        name: `V`,
        data: Object.values(this.$store.state.object.stat.graph_v).map(v => parseFloat(v.toFixed(3))).reverse()
      }]
    },

    getVGraphOptions () {
      return {
        chart: {
          id: 'vuechart-v',
          height: '100%'
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: Object.keys(this.$store.state.object.stat.graph_v).reverse()
        }
      }
    },

    getQGraphOptions () {
      if (this.$store.state.object.stat) {
        return {
          chart: {
            id: 'vuechart-q',
            height: '100%'
          },
          colors: ['#fb881e'],
          stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: Object.keys(this.$store.state.object.stat.graph_e).reverse()
          }
        }
      } else {
        return {}
      }
    },

  },

  methods: {

    getTemp (date) {
      const day = this.$store.state.weather.filter(w => date.includes(w.date))[0]
      if (day) {
        return day.temperature
      } else {
        return ''
      }
    },

    customFormatter (date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },

    setDateFrom () {
      if (
        this.$moment(this.$store.state.date_to) > this.$moment(this.$store.state.date_from).add(6, 'month') ||
        this.$moment(this.$store.state.date_to) < this.$moment(this.$store.state.date_from)
      ) {
        let to = this.$moment(this.$store.state.date_from).add(6, 'month')
        if (to > this.$moment()) {
          to = this.$moment()
        }
        this.$store.commit('setData', {
          attr: 'date_to',
          value: to.toDate()
        })
      }
    },

    calculateTableHeight () {
      const table = document.getElementsByTagName('thead')[0]
      if (table) {
        const height = window.innerHeight - document.getElementsByTagName('footer')[0].offsetHeight - table.getBoundingClientRect().top - 40
        this.table_height = `${height}px`
      }
    }
  },

  async mounted () {
    try {
      this.$store.commit('mergeStore', {
        object: {
          id: this.$route.params.id
        },
        loading: true
      })
      await this.$store.dispatch('getObjectData')
      this.calculateTableHeight()
      window.addEventListener('resize', () => {
        this.calculateTableHeight()
      }, true);
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`, {duration: 1000})
    }
  }
}
</script>

<style lang="scss">
.vdp-datepicker{
  input{
    width: 120px;
  }
}
#object-table{
  // .th-w-85{
  //   width: 85px;
  // }
  td{
    text-align: center;
    width: 7.1428%;
  }
}

.overflow-x-hidden{
  overflow-x: hidden;
}

.dropdown-objects{
  .btn{
    white-space: pre-wrap;
  }
}
</style>
