import axios from 'axios'
const moment = require('moment')

function getToken(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const api = {
  // async logInGetToken(username: string, password: string) {
  //   const params = new URLSearchParams();
  //   params.append('username', username);
  //   params.append('password', password);
  //
  //   return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  // },
  async signup (context) {

    const formData = new FormData()
    formData.append('file', context.state.user_file)
    formData.append('data', JSON.stringify(context.state.user))
    return axios.post('/api/signup', formData, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async login (context) {
    return axios.post('/api/login', {
      username: context.state.user.username,
      password: context.state.user.password,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async passwordRecovery (state) {
    return axios.post('/api/password', {
      email: state.user.email,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async logout () {
    return axios.post('/api/logout', {}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async getMe () {
    return axios.get('/api/me')
  },
  async updateAccount (context) {
    return axios.put(`/api/user_update`, {
      user: context.state.user
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async runParser () {
    return axios.get('/api/parse')
  },
  async getConfig () {
    return axios.get('/api/config')
  },
  async saveConfig (context) {
    return axios.post(`/api/config`, context.state.config, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getGroups (context) {
    return axios.get('/api/manage/groups', {params: context.state.groups_filter})
  },
  async saveGroup (context) {
    return axios.post(`/api/manage/groups`, context.state.group, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async deleteGroup (context) {
    return axios.delete(`/api/manage/groups/${context.state.group.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getManageObjects (context) {
    return axios.get('/api/manage/objects', {params: context.state.objects_filter})
  },
  async saveObject (context) {
    return axios.post(`/api/manage/objects`, context.state.object, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async deleteObject (context) {
    return axios.delete(`/api/manage/objects/${context.state.object.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getManageApartments (context) {
    return axios.get('/api/manage/apartments', {params: context.state.apartments_filter})
  },
  async saveApartment (context) {
    return axios.post(`/api/manage/apartments`, context.state.apartment, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async deleteApartment (context) {
    return axios.delete(`/api/manage/apartments/${context.state.apartment.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getManageDevices (context) {
    return axios.get('/api/manage/devices', {params: context.state.devices_filter})
  },
  async linkDevice (context) {
    return axios.post(`/api/manage/devices`, context.state.device, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async linkNextDevice (context) {
    const device = { ...context.state.device }
    delete device.id
    return axios.post(`/api/manage/devices/link`, device, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async deleteDevice (context) {
    return axios.delete(`/api/manage/devices/${context.state.device.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async unlinkDevice (context) {
    return axios.post(`/api/manage/devices/${context.state.device.id}/unlink`, {}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getUserDevices () {
    // console.log('context.state.serverParams', context.state.serverParams)
    return axios.get('/api/devices')
  },
  async getDeviceData (context) {
    // console.log('context.state.serverParams', context.state.serverParams)
    return axios.get(`/api/devices/${context.state.device.id}`, {
      params: {
        from: context.state.date_from,
        to: context.state.date_to,
      }
    })
  },
  async getObjectData (context) {
    return axios.get(`/api/objects/${context.state.object.id}`, {
      params: {
        from: moment(context.state.date_from).unix(),
        to: moment(context.state.date_to).unix(),
        startOfDay: moment().startOf('day').unix(),
      }
    })
  },
  async getObjects (state) {
    return axios.get('/api/objects', {
      params: {
        group_id: state.user.customer.group_id
      }
    })
  },
  async getData () {
    return axios.get('/api/data')
  },

  async getWeather () {
    return axios.get('/api/weather')
  },

  async getApartments (context) {
    return axios.get('/api/apartments', {
      params: {
        object_id: context.state.user.customer.object_id
      }
    })
  },

  async getManageCustomers (context) {
    return axios.get('/api/manage/customers', {params: context.state.customers_filter})
  },

  async changeActivateUser (context) {
    return axios.post(`/api/manage/users/${context.state.customer.user.id}`, {is_active: !context.state.customer.user.is_active}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
  async sendUserCreds (context) {
    return axios.post(`/api/manage/users/${context.state.customer.user.id}/send_creds`, {}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async deleteCustomer (context) {
    return axios.delete(`/api/manage/users/${context.state.customer.user.id}/delete`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

};
