<template lang="html">
  <div class="p-4" v-if="$store.state.device.data">

    <div class="text-center">
      <div class="h5">
        Ведомость учета тепловой энергии
      </div>
      <b-dropdown
        variant="light"
        toggle-class="mb-3"
        :text="getDeviceTitle">
        <b-dropdown-item
          v-for="device in $store.state.devices"
          :key="device.id"
          :to="'/reports/' + device.id">
          {{ device.serial_number }} {{ device.man }} {{ device.device_type }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="mb-3 d-flex justify-content-center">
      <Datepicker
        v-model="$store.state.date_from"
        :format="customFormatter"
        @input="setDateFrom"
        :disabled-dates="{
          from: $moment().toDate()
        }"
        :language="ru"
        :monday-first="true"
        input-class="form-control"/>
      <span class="mx-3 pt-2">-</span>
      <Datepicker
        v-model="$store.state.date_to"
        :format="customFormatter"
        :disabled-dates="{
          to: $store.state.date_from,
          from: $moment($store.state.date_from).add(6, 'month').toDate()
        }"
        :language="ru"
        :monday-first="true"
        input-class="form-control"/>

        <a
          :href="'/api/report/device/' + $store.state.device.id + '?format=xlsx&from=' + $moment($store.state.date_from).utc().format() + '&to=' + $moment($store.state.date_to).utc().format()"
          target="_blank"
          class="ml-4">
          <img src="/img/excel.png" height="26">
        </a>

        <a
          :href="'/api/report/device/' + $store.state.device.id + '?format=pdf&from=' + $moment($store.state.date_from).utc().format() + '&to=' + $moment($store.state.date_to).utc().format()"
          target="_blank"
          class="ml-3 text-danger">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
            <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
            <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
          </svg>
        </a>

    </div>

    <div class="row mb-3 d-none d-lg-flex">
      <div class="col-6 mx-auto d-flex justify-content-between">
        <div class="">
          Адрес
        </div>
        <div class="">
          {{ $store.state.device.linked }}
        </div>
      </div>
      <div class="col-12"></div>
      <div class="col-6 mx-auto d-flex justify-content-between">
        <div class="">
          Номер помещения
        </div>
        <div class="">
          {{ $store.state.device.apartment_number }}
        </div>
      </div>
      <div class="col-12"></div>
      <div class="col-6 mx-auto d-flex justify-content-between">
        <div class="">
          Тип помещения
        </div>
        <div class="">
          {{ {'residential': 'Жилое', 'commercial': 'Коммерческое'}[$store.state.device.apartment_type] }}
        </div>
      </div>
      <div class="col-12"></div>
      <div class="col-6 mx-auto d-flex justify-content-between">
        <div class="">
          Устройство (тип, модель, серийный номер)
        </div>
        <div class="">
          {{ getDeviceTitle }}
        </div>
      </div>
      <div class="col-12"></div>
    </div>

    <div class="fs-14 d-lg-none mb-3">
      <strong>
        {{ $store.state.device.man }}
        № {{ $store.state.device.serial_number }}
      </strong>
      <div class="">
        <strong>{{ ['heat_flow_pipe', 'heat_return_pipe'].includes($store.state.device.device_type) ? 'Теплосчетчик' : 'Неизвестное устройство' }}</strong>
        по адресу
        {{ $store.state.device.object.group_name }}
        {{ $store.state.device.object.name }}
        № {{ $store.state.device.apartment_number }}
        ({{ {'residential': 'Жилое', 'commercial': 'Коммерческое'}[$store.state.device.apartment_type] }})
      </div>
    </div>

    <b-table
      head-variant="light"
      responsive
      bordered
      small
      :fields="fields"
      :items="$store.state.device.data">
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(date_time_of_request)="data">
        {{ data.item.date_time_of_request | moment('DD.MM.YYYY HH:mm') }}
      </template>
      <template #cell(egcal)="data">
        <span v-if="$store.state.device.data[data.index + 1]">
          {{ new Intl.NumberFormat().format(data.item.e - $store.state.device.data[data.index + 1].e) }}
        </span>
      </template>
      <template #cell(vm3)="data">
        <span v-if="$store.state.device.data[data.index + 1]">
          {{ new Intl.NumberFormat().format(data.item.v - $store.state.device.data[data.index + 1].v) }}
        </span>
      </template>
      <template #cell(mt)>
        15
      </template>
    </b-table>

    <div class="table-responsive mb-3">
      <table class="table table-bordered table-sm">
        <tbody>
          <tr>
            <td>Суммарно ΔQ за период</td>
            <td>
              <span v-if="$store.state.device.data.length > 1">
                {{ new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 3}).format($store.state.device.data[0].e - $store.state.device.data[$store.state.device.data.length - 1].e) }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Суммарно ΔV за период</td>
            <td>
              <span v-if="$store.state.device.data.length > 1">
                {{ new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 3}).format($store.state.device.data[0].v - $store.state.device.data[$store.state.device.data.length - 1].v) }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Q (за период) * тариф</td>
            <td>
              <span v-if="$store.state.device.data.length > 1">
                {{ new Intl.NumberFormat('ua-UA', { style: 'currency', currency: 'UAH' }).format(($store.state.device.data[0].e - $store.state.device.data[$store.state.device.data.length - 1].e) * $store.state.device.object.heat_tariff) }}
              </span>
              <span class="text-danger">(тариф - {{ new Intl.NumberFormat('ua-UA', { style: 'currency', currency: 'UAH' }).format($store.state.device.object.heat_tariff) }})</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center">
      График посуточного потребления тепловой энергии, Q
    </div>

    <apexchart
      type="line"
      :options="getGraphOptions"
      :series="getGraphData">
    </apexchart>

    <div class="">
      <span v-for="(period, i) in getMonths" :key="i">
        <button
          @click="setPeriod(period)"
          type="button"
          class="btn btn-link">
          {{ period.name }}
        </button>
      </span>
    </div>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
const vdp_translation = require('vuejs-datepicker/dist/locale/translations/ru.js')

export default {
  name: 'Device',

  components: {
    Datepicker
  },

  data: function () {
    return {
      ru: vdp_translation,
      fields: [
        {
          key: 'index',
          label: '№'
        },
        {
          label: 'Дата и время опроса устройства',
          key: 'date_time_of_request',
          sortable: false
        },
        {
          label: 'Q',
          key: 'e_origin',
          variant: 'warning',
          tdClass: 'bg-heat',
          sortable: false
        },
        {
          label: 'Q (ед)',
          key: 'eu',
          tdClass: 'bg-heat',
          sortable: false
        },
        {
          label: 'ΔQ (ГКал)',
          key: 'egcal',
          tdClass: 'bg-deep-heat',
          sortable: false
        },
        {
          label: 'V',
          key: 'v',
          tdClass: 'bg-water',
          sortable: false
        },
        {
          label: 'V (ед)',
          key: 'vu',
          tdClass: 'bg-water',
          sortable: false
        },
        {
          label: 'ΔV (м³)',
          key: 'vm3',
          tdClass: 'bg-deep-water',
          sortable: false
        },
        {
          label: 'T1 (°C)',
          key: 't1',
          sortable: false
        },
        {
          label: 'T2 (°C)',
          key: 't2',
          sortable: false
        },
        {
          label: 'ΔT (K)',
          key: 'dt',
          sortable: false
        },
        {
          label: 'Ср. сут. т. в. (°C)',
          key: 'mt',
          sortable: false
        },
      ]
    }
  },

  watch:{
    $route (to) {
      this.$store.commit('setData', {
        attr: 'device',
        value: {
          id: to.params.id
        }
      })
      this.$store.dispatch('getDeviceData')
    },
    '$store.state.date_from' () {
      this.$store.dispatch('getDeviceData')
    },
    '$store.state.date_to' () {
      this.$store.dispatch('getDeviceData')
    }
  },

  computed: {

    getMonths () {
      const dates = []
      let start = this.$moment(this.$store.state.date_from).startOf('month')
      console.log('start', this.$store.state.date_from, start.format())
      while (start < this.$store.state.date_to) {
        // console.log('from', from.format(), to.format())
        dates.push({
          name: start.format('MMMM YY'),
          from: start.toDate(),
          to: start.add(1, 'month').toDate(),
        })
      }
      return dates
    },

    getDeviceTitle () {
      return `${this.$store.state.device.serial_number} ${this.$store.state.device.man} ${this.$store.state.device.device_type}`
    },

    getGraphData () {
      const data = []
      for (var i = 0; i < this.$store.state.device.data.length - 1; i++) {
        let e = this.$store.state.device.data[i].e - this.$store.state.device.data[i + 1].e
        data.push(parseFloat(e.toFixed(3)))
      }
      return [{
        name: `Q`,
        data: data.reverse()
      }]
    },

    getGraphOptions () {
      return {
        chart: {
          id: 'vuechart-example',
          height: 300
        },
        colors: ["#fb881e"],
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.$store.state.device.data.map(d => this.$moment(d.date_time_of_request).format('L')).reverse()
        }
      }
    }

  },

  methods: {

    setPeriod (period) {
      console.log('period', period);
      this.$store.commit('setData', {
        attr: 'date_from',
        value: period.from
      })
      this.$store.commit('setData', {
        attr: 'date_to',
        value: period.to
      })
    },

    customFormatter (date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },

    setDateFrom () {
      if (
        this.$moment(this.$store.state.date_to) > this.$moment(this.$store.state.date_from).add(6, 'month') ||
        this.$moment(this.$store.state.date_to) < this.$moment(this.$store.state.date_from)
      ) {
        let to = this.$moment(this.$store.state.date_from).add(6, 'month')
        if (to > this.$moment()) {
          to = this.$moment()
        }
        this.$store.commit('setData', {
          attr: 'date_to',
          value: to.toDate()
        })
      }
    }
  },

  async mounted () {
    try {
      this.$store.commit('setData', {
        attr: 'device',
        value: {
          id: this.$route.params.id
        }
      })
      // this.$store.commit('setData', {
      //   attr: 'date_to',
      //   value: this.$moment().toDate()
      // })
      // this.$store.commit('setData', {
      //   attr: 'date_from',
      //   value: this.$moment().subtract(6, 'month').toDate()
      // })
      this.$store.dispatch('getDeviceData')
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`, {duration: 1000})
    }
  }
}
</script>
