<template lang="html">
  <footer class="mt-auto d-flex justify-content-center">
    <div class="text-center fs-12 py-2">
      <img src="/img/logo.png" height="40" class="mb-1">
      <div class="px-4feee">
        {{ {
          ru: 'Ваш аккаунт для мониторинга приборов учета',
          ua: 'Ваш аккаунт для моніторингу приладів обліку',
        }[$store.state.lang] }}
      </div>
      <hr class="border-gray my-0">
      <div class="">
        2015-{{year}}, {{ {ru: 'Киев', ua: 'Київ'}[$store.state.lang] }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      year: null
    }
  },

  mounted () {
    const date = new Date()
    this.year = date.getFullYear()
  }
}
</script>

<style lang="css" scoped>
/* footer{
  background-color: white;
  position: fixed;
  bottom: 0;
  left: calc(50% + 10px);
} */
</style>
