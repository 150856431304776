import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import {api} from './api'
import {router} from './main'
const moment = require('moment')
const store = new Vuex.Store({
  state: {
    lang: 'ru',
    vw: 1200,
    loading: false,
    auth: 'login',
    user: {
      customer: {
        apartment_id: null
      }
    },
    user_file: null,
    user_edit_field: null,
    devices: [],
    device: {},
    date_from: moment().startOf('month').toDate(),
    date_to: moment().toDate(),
    objects: [],
    object: {},
    apartments: [],
    apartment: {},
    weather: [],

    objects_filter: {
      group_id: null,
      page: 1
    },
    apartments_filter: {
      page: 1,
      apartment_type: null,
      group_id: null,
      object_id: null,
    },
    devices_filter: {
      sortBy: null,
      sortDesc: null,
      page: 1,
      serial_number_from: null,
      serial_number_to: null,
      man: null,
      device_type: null,
      device_status: null,
      linked: null,
      ne_mcl_id: false,
      mcl_id: null
    },
    groups: [],
    total_groups: null,
    total_objects: null,
    total_apartments: null,
    total_devices: null,
    total_customers: null,
    customers: [],
    customers_filter: {
      date_joined_from: null,
      date_joined_to: null,
      page: 1,
    },
    group: {},
    groups_filter: {
      page: 1
    },

    users: [],
    config: {
      parse_time: null
    },
    i18n: {
      ru: {
        objects: 'Объекты',
        devices: 'Устройства',
        stat: 'Статистика за период',
        notif: 'Оповещения и лимиты',
        predict: 'Прогноз потребления',
        report: 'Формирование отчетов',
        invoice: 'Формирование счетов',
        settings: 'Настройка аккаунта',
        info: 'Справка и поддержка',
        logout: 'Выход',
        notifCopy: 'Скопировано',
        notifSuccessSaved: 'Успешно сохранено',
        notifSuccessDeleted: 'Успешно удалено',
        notifSuccessUnlinked: 'Успешно отвязано',
        notifSuccessSended: 'Успешно отправлено',
        notifErrorLogin: 'Неправильные логин / пароль',
        notifParserStarted: 'Парсер запущен',
        confirmDeleteAccount: 'Вы точно хотите удалить Ваш аккаунт из системы?',
        notifDeveloped: 'Данная функция в разработке',
      },
      ua: {
        objects: "Об'єкти",
        devices: "Пристрої",
        stat: 'Статистика за період',
        notif: 'Оповіщення та ліміти',
        predict: 'Прогноз споживання',
        report: 'Формування звітів',
        invoice: 'Формування рахунків',
        settings: 'Налаштування аккаунту',
        info: 'Довідка та підтримка',
        logout: 'Вихід',
        notifCopy: 'Скопійовано',
        notifSuccessSaved: 'Успішно збережено',
        notifSuccessDeleted: 'Успішно видалено',
        notifSuccessUnlinked: "Успішно відв'язано",
        notifSuccessSended: 'Успішно надіслано',
        notifErrorLogin: 'Неправильні логін / пароль',
        notifParserStarted: 'Парсер запущено',
        confirmDeleteAccount: 'Ви точно хочете видалити Ваш обліковий запис із системи?',
        notifDeveloped: 'Ця функція у розробці',

      }
    }
  },
  mutations: {
    setUser (state, data) {
      state.user = data
    },
    setData (state, {attr, value}) {
      state[attr] = value
    },
    mergeStore (state, data) {
      for (const key of Object.keys(data)) {
        state[key] = data[key]
      }
    },
  },
  actions: {
    async runParser ({state}) {
      try {
        const response = await api.runParser()
        if (response.status === 200) {
          Vue.toasted.success(state.i18n[state.lang]['notifParserStarted'])
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },
    async getConfig (context) {
      try {
        const response = await api.getConfig()
        if (response.data) {
          context.commit('mergeStore', {
            config: response.data,
          })
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },
    async saveConfig (context) {
      try {
        const response = await api.saveConfig(context)
        if (response.data) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getConfig')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async signup (context) {
      try {
        const response = await api.signup(context)
        if (response.status === 200 && response.data.status === 'success') {
          context.state.auth = 'login'
          Vue.toasted.success(response.data.message, {duration: 8000})
        } else {
          Vue.toasted.error(response.data.message)
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async login (context) {
      try {
        const response = await api.login(context)
        if (response.status === 200 && response.data) {
          if (response.data.status === "not auth") {
            Vue.toasted.error(context.state.i18n[context.state.lang]['notifErrorLogin'])
          } else {
            router.push('/')
            context.dispatch('getUser')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async passwordRecovery ({ state }) {
      try {
        const response = await api.passwordRecovery(state)
        if (response.status === 200 && response.data) {
          if (response.data.status === 'success') {
            Vue.toasted.success(response.data.message)
          } else if (response.data.status === 'error') {
            Vue.toasted.error(response.data.message)
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async logout (context) {
      try {
        const response = await api.logout(context)
        if (response.status === 200) {
          context.commit('setUser', {customer: {}})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getUser (context) {
      try {
        const response = await api.getMe()
        if (response.data) {
          context.commit('mergeStore', {
            user: response.data,
            lang: response.data.customer.lang || 'ru',
          })
          if (response.data.is_superuser) {
            context.dispatch('getData')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async updateAccount (context) {
      try {
        const response = await api.updateAccount(context)
        if (response.data) {
          context.commit('mergeStore', {
            user_edit_field: null,
            lang: response.data.customer.lang
          })
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteAccount (context) {
      try {
        if (confirm(context.state.i18n[context.state.lang]['confirmDeleteAccount'])) {
          const response = await api.deleteAccount()
          if (response.data.status == 'success') {
            Vue.toasted.info(`Аккаунт успешно удален`)
            context.commit('setUser', {})
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getUserDevices (context) {
      try {
        const response = await api.getUserDevices()
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'devices', value: response.data})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getDeviceData (context) {
      try {
        const response = await api.getDeviceData(context)
        if (response.status === 200 && response.data) {
          context.commit('mergeStore', {
            device: response.data,
            loading: false
          })
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getObjectData (context) {
      try {
        const response = await api.getObjectData(context)
        if (response.status === 200 && response.data) {
          context.commit('mergeStore', {
            object: response.data,
            loading: false
          })
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getObjects ({ state, commit }) {
      try {
        const response = await api.getObjects(state)
        if (response.status === 200 && response.data) {
          commit('setData', {attr: 'objects', value: response.data})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getData (context) {
      try {
        const response = await api.getData(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'groups', value: response.data.groups})
          context.commit('setData', {attr: 'total_groups', value: response.data.total_groups})
          context.commit('setData', {attr: 'objects', value: response.data.objects})
          context.commit('setData', {attr: 'total_objects', value: response.data.total_objects})
          context.commit('setData', {attr: 'apartments', value: response.data.apartments})
          context.commit('setData', {attr: 'total_apartments', value: response.data.total_apartments})
          context.commit('setData', {attr: 'devices', value: response.data.devices})
          context.commit('setData', {attr: 'total_devices', value: response.data.total_devices})
          context.commit('setData', {attr: 'customers', value: response.data.customers})
          context.commit('setData', {attr: 'total_customers', value: response.data.total_customers})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getApartments (context) {
      try {
        const response = await api.getApartments(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'apartments', value: response.data})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getWeather (context) {
      try {
        const response = await api.getWeather()
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'weather', value: response.data})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getGroups (context) {
      try {
        const response = await api.getGroups(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'groups', value: response.data.groups})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async saveGroup (context) {
      try {
        const response = await api.saveGroup(context)
        if (response.data) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getGroups')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteGroup (context) {
      try {
        if (confirm('Точно удалять?')) {
          const response = await api.deleteGroup(context)
          if (response.status === 204) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
            context.dispatch('getGroups')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getManageObjects (context) {
      try {
        const response = await api.getManageObjects(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'objects', value: response.data.objects})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async saveObject (context) {
      try {
        const response = await api.saveObject(context)
        if (response.data) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getManageObjects')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteObject (context) {
      try {
        if (confirm('Точно удалять?')) {
          const response = await api.deleteObject(context)
          if (response.status === 204) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessDeleted'])
            context.dispatch('getManageObjects')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getManageApartments (context) {
      try {
        const response = await api.getManageApartments(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'apartments', value: response.data.apartments})
          context.commit('setData', {attr: 'total_apartments', value: response.data.total_apartments})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async saveApartment (context) {
      try {
        const response = await api.saveApartment(context)
        if (response.data) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getManageApartments')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteApartment (context) {
      try {
        if (confirm('Точно удалять?')) {
          const response = await api.deleteApartment(context)
          if (response.status === 204) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessDeleted'])
            context.dispatch('getManageApartments')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getManageDevices (context) {
      try {
        const response = await api.getManageDevices(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'devices', value: response.data.devices})
          context.commit('setData', {attr: 'total_devices', value: response.data.total_devices})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async linkDevice (context) {
      try {
        const response = await api.linkDevice(context)
        console.log('saveDevice', response);
        if (response.data.status === 'error') {
          Vue.toasted.error(response.data.message)
        } else {
          context.state.device = {
            ...response.data,
            apartment_id: response.data.apartment,
            object_id: response.data.object,
            group_id: response.data.group,
          }
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getManageDevices')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async linkNextDevice (context) {
      try {
        const response = await api.linkNextDevice(context)
        if (response.data.status === 'error') {
          Vue.toasted.error(response.data.message)
        } else {
          context.state.device = {
            ...response.data,
            apartment_id: response.data.apartment,
            object_id: response.data.object,
            group_id: response.data.group,
          }
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getManageDevices')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteDevice (context) {
      try {
        if (confirm('Точно удалять?')) {
          const response = await api.deleteDevice(context)
          if (response.status === 204) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessDeleted'])
            context.dispatch('getManageDevices')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async unlinkDevice (context) {
      try {
        if (confirm('Отвязать устройство?')) {
          const response = await api.unlinkDevice(context)
          if (response.status === 200) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessUnlinked'])
            context.dispatch('getManageDevices')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async getManageCustomers (context) {
      try {
        const response = await api.getManageCustomers(context)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'customers', value: response.data.customers})
          context.commit('setData', {attr: 'total_customers', value: response.data.total_customers})
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },
    //
    // async saveCustomer (context) {
    //   try {
    //     const response = await api.saveCustomer(context)
    //     if (response.data) {
    //       Vue.toasted.success('Успешно сохранено')
    //       context.dispatch('getManageCustomers')
    //     }
    //   } catch (e) {
    //     Vue.toasted.error(`${e.name}: ${e.message}`)
    //   }
    // },

    async changeActivateUser (context) {
      try {
        const response = await api.changeActivateUser(context)
        if (response.status === 200) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSaved'])
          context.dispatch('getManageCustomers')
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async sendUserCreds (context) {
      try {
        const response = await api.sendUserCreds(context)
        if (response.status === 200) {
          Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessSended'])
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteCustomer (context) {
      try {
        if (confirm('Точно удалять?')) {
          const response = await api.deleteCustomer(context)
          if (response.status === 204) {
            Vue.toasted.success(context.state.i18n[context.state.lang]['notifSuccessDeleted'])
            context.dispatch('getManageCustomers')
          }
        }
      } catch (e) {
        Vue.toasted.error(`${e.name}: ${e.message}`)
      }
    },

  }
})

export {
  store
}
