<template>
  <div class="p-3 p-lg-4">
    <div v-if="$store.state.lang === 'ru'" class="">
      <h4>Панель объекта</h4>
      <p>Предназначена для предоставления общей информации по устройствам, смонтированным на объекте, копирования данной информации в буфер обмена, инициации мгновенно опроса.</p>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <img src="/img/adminwidget-ru.png" class="mw-100">
        </div>
      </div>
      <p>[1] – Тип объекта и название.</p>
      <p>[2] – Адрес объекта.</p>
      <p>[3] – Скопировать данные панели устройства в текстовом формате в буфер обмена.</p>
      <p class="text-black-50">[4] - Мгновенный опрос устройства – служит для получения текущих данных с устройств, не дожидаясь опроса, инициированного сервером с последующим занесением в базу данных свежей информации. </p>
      <p>[5] - Накопленная тепловая энергия по всем квартирным теплосчетчикам на объекте за весь период работы.</p>
      <p>[6] - Накопленная тепловая энергия по всем квартирным  теплосчетчикам на объекте с начала текущего месяца,  обнуляется с 1-го числа каждого месяца.</p>
      <p>[7] - Накопленный объем теплоносителя на всех квартирных теплосчетчиках за весь период работы.</p>
      <p>[8] - Накопленный объем теплоносителя на всех квартирных теплосчетчиках с начала текущего месяца, обнуляется с 1-го числа каждого месяца.</p>
      <p>[9] – Количество квартирных теплосчетчиков, подключенных к системе.</p>
      <p>[10] – Количество квартирных теплосчетчиков, не ответивших на последний опрос.</p>
      <p>[11] – Количество квартирных теплосчетчиков, сгенерировавших код ошибки во время последнего опроса.</p>
      <p>[12] - Накопленная тепловая энергия по всем домовым теплосчетчикам на объекте за весь период работы.</p>
      <p>[13]  - Накопленная тепловая энергия по всем домовым  теплосчетчикам на объекте с начала текущего месяца,  обнуляется с 1-го числа каждого месяца.</p>
      <p>[14] - Накопленный объем теплоносителя на всех домовых теплосчетчиках за весь период работы.</p>
      <p>[15] - Накопленный объем теплоносителя на всех домовых теплосчетчиках с начала текущего месяца, обнуляется с 1-го числа каждого месяца.</p>
      <p>[16] – Количество домовых   теплосчетчиков, подключенных к системе.</p>
      <p>[17] – Количество домовых   теплосчетчиков, не ответивших на последний опрос.</p>
      <p>[18] – Домовые теплосчетчики, сгенерировавшие код ошибки во время последнего опроса.</p>

      <h4>Статистика за период</h4>
      <p class="lead">Данная вкладка служит для детализированного просмотра данных с устройств с представлением в виде таблиц и графиков по временным точкам опроса за выбранный период времени, формированием отчетов в форматах PDF и Excel.</p>
      <img src="/img/adminstat.png" class="mw-100">

      <p>[19]  - Выбор объекта по которому будут отображены данные</p>
      <p>[20] – Выбор периода времени для отображения данных и формирования отчетов с устройств.</p>
      <p>[21] – Формирование отчетов в форматах PDF, Excel за выбранный период времени.</p>
      <p>[22] – Дата и время последних данных с устройства.</p>
      <p>[23] – Номер помещения где смонтировано устройство.</p>
      <p>[24] – Тип помещения(жилое/комерческое) где смонтировано устройство.</p>
      <p>[25] – Код производителя устройства.</p>
      <p>[26] – Серийный номер устройства, при клике на который откроется детальная информация по устройству за выбранный период времени.</p>
      <p>[27] – Тепловая энергия в единицах устройства.</p>
      <p>[28] – Единицы тепловой энергия устройства.</p>
      <p>[29] – Потреблённая тепловая энергия за выбранный период в Гкал,  вычисляемая как:</p>
      <p class="text-center"><strong>Q конечная дата – Q начальная дата = ΔQ , Гкал</strong></p>

      <p>[30] – Объем в единицах устройства.</p>
      <p>[31] – Единицы объема устройства.</p>
      <p>[32] – Потреблённый объем теплоносителя за выбранный период в м³,  вычисляемый как:</p>
      <p class="text-center"><strong>V конечная дата – V начальная дата = ΔV , м³</strong></p>
      <p>[33] – Статус устройства.</p>
      <p>[34] – Код ошибки/состояния устройства.</p>
      <p>[35] – Версия прошивки устройства.</p>
    </div>
    <div v-else-if="$store.state.lang === 'ua'" class="">
      <h4>Панель об'єкта</h4>
      <p>Призначена для надання загальної інформації щодо пристроїв, змонтованих на об'єкті, копіювання даної інформації в буфер обміну, ініціації миттєвого опитування.</p>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <img src="/img/adminwidget-ua.png" class="mw-100">
        </div>
      </div>
      <p>[1] – Тип об'єкта та назва.</p>
      <p>[2] – Адреса об'єкта.</p>
      <p>[3] – Копіювати дані панелі пристроїв в текстовому форматі до буфера обміну.</p>
      <p class="text-black-50">[4] - Миттєве опитування пристроїв – служить для отримання поточних даних із пристроїв, не чекаючи опитування, ініційованого сервером з наступним занесенням до бази даних оновленої інформації.</p>
      <p>[5] - Накопичена теплова енергія за всіма квартирними теплолічильниками на об'єкті за весь період роботи.</p>
      <p>[6] - Накопичена теплова енергія за всіма квартирними теплолічильниками на об'єкті з початку поточного місяця, обнулюється з 1-го числа кожного місяця.</p>
      <p>[7] - Накопичений об’єм теплоносія на всіх квартирних теплолічильниках за весь період роботи.</p>
      <p>[8] - Накопичений об’єм теплоносія на всіх квартирних теплолічильниках з початку поточного місяця, обнулюється з 1 числа кожного місяця.</p>
      <p>[9] – Кількість квартирних теплолічильників підключених до системи.</p>
      <p>[10] – Кількість квартирних теплолічильників, які не відповіли на останнє опитування.</p>
      <p>[11] – Кількість квартирних теплолічильників, які згенерували код помилки під час останнього опитування.</p>
      <p>[12] - Накопичена теплова енергія за всіма будинковими теплолічильниками на об'єкті за весь період роботи.</p>
      <p>[13] - Накопичена теплова енергія за всіма будинковими теплолічильниками на об'єкті з початку поточного місяця, обнулюється з 1-го числа кожного місяця.</p>
      <p>[14] - Накопичений об’єм теплоносія на всіх будинкових теплолічильниках за весь період роботи.</p>
      <p>[15] - Накопичений об’єм теплоносія на всіх будинкових теплолічильниках з початку поточного місяця, обнулюється з 1 числа кожного місяця.</p>
      <p>[16] – Кількість будинкових теплолічильників, підключених до системи.</p>
      <p>[17] – Кількість будинкових теплолічильників, які не відповіли на останнє опитування.</p>
      <p>[18] – Будинкові теплолічильники, що згенерували код помилки під час останнього опитування.</p>

      <h4>Статистика за період</h4>
      <p>Ця вкладка служить для детального перегляду даних із пристроїв з поданням у вигляді таблиць та графіків за часовими точками опитування за вибраний період часу, формуванням звітів у форматах PDF та Excel.</p>
      <img src="/img/adminstat.png" class="mw-100">

      <p>[19] - Вибір об'єкта, за яким будуть відображені дані.</p>
      <p>[20] – Вибір періоду часу для відображення даних та формування звітів із пристроїв.</p>
      <p>[21] – Формування звітів у форматах PDF, Excel за вибраний період.</p>
      <p>[22] – Дата та час останніх даних із пристрою.</p>
      <p>[23] – Номер приміщення, де змонтовано пристрій.</p>
      <p>[24] – Тип приміщення (житлове/комерційне), де змонтовано пристрій.</p>
      <p>[25] – Код виробника пристрою.</p>
      <p>[26] – Серійний номер пристрою, при натисканні на який відкриється детальна інформація щодо пристрою за вибраний період часу.</p>
      <p>[27] – Теплова енергія у одиницях пристрою.</p>
      <p>[28] – Одиниці теплової енергії пристрою.</p>
      <p>[29] – Спожита теплова енергія за обраний період  в Гкал, що обчислюється як:</p>
      <p class="text-center"><strong>Q кінцева дата – Q початкова дата = ΔQ , Гкал</strong></p>

      <p>[30] – Одиниці об'єму пристрою.</p>
      <p>[31] – Одиниці об'єму пристрою.</p>
      <p>[32] – Спожитий об'єм теплоносія за вибраний період , що обчислюеться як:</p>
      <p class="text-center"><strong>V кінцева дата – V початкова дата = ΔV , м³</strong></p>
      <p>[33] – Статус пристрою.</p>
      <p>[34] – Код помилки/стану пристрою.</p>
      <p>[35] – Версія прошивки пристрою.</p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
